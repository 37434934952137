import { DEFAULT_LIMITED_TO_DISPLAY } from '@/lib/constant/c.system'

export const DEFAULT_COUNT_LIMITED_INSTITUTIONS_TO_DISPLAY = DEFAULT_LIMITED_TO_DISPLAY

export const INSTITUTIONS_GETTER_GET_LIST = 'institutions_getter_get_list'
export const INSTITUTIONS_GETTER_GET_LIMITED_LIST = 'institutions_getter_get_limited_list'
export const INSTITUTIONS_GETTER_GET_PAGE = 'institutions_getter_get_page'

export const INSTITUTIONS_MUTATION_ADD_ITEMS = 'institutions_mutation_add_items'

export const INSTITUTIONS_ACTION_FETCH = 'institutions_action_fetch'
