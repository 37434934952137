import {
  MODALS_COMMON_GETTER_GET_MODAL_NAME,
  MODALS_COMMON_GETTER_GET_IS_OPEN
} from '@/store/modules/modals/common/modalCommon.constant'

export default {
  /**
   *
   * @param {{modalName}} state
   * @return {*}
   */
  [MODALS_COMMON_GETTER_GET_MODAL_NAME]: (state) => {
    if (typeof state.modalName === 'undefined') {
      throw new Error('In modal windows store do not set state.modalName now state' + JSON.stringify(state))
    }
    return state.modalName
  },
  [MODALS_COMMON_GETTER_GET_IS_OPEN]: (state) => {
    if (typeof state.open === 'undefined') {
      throw new Error('In modal windows store do not set state.open now state' + JSON.stringify(state))
    }
    return state.open
  }
}
