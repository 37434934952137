import {
  SYSTEM_BREADCRUMB_MUTATION_SET_LIST,
  SYSTEM_BREADCRUMB_MUTATION_ADD_ITEM
} from '@/store/modules/system/breadcrumb/systemBreadcrumb.constant'

/**
 *
 * @param {{name, redirect?: {name}, params, meta: {?title, breadcrumb}}} routerItem
 * @param isCustom
 * @return {{name: *, breadcrumb: String}}
 */
function getBreadcrumbFormatting (routerItem, isCustom = false) {
  const result = {
    name: routerItem.name || routerItem.redirect.name,
    params: routerItem.params,
    breadcrumb: undefined
  }
  const breadcrumb = routerItem.meta?.breadcrumb
  if (breadcrumb) {
    if (breadcrumb.custom === true) {
      return undefined
    }
    result.breadcrumb = breadcrumb
  } else {
    return undefined
    // result.breadcrumb = undefined
  }
  if (typeof result.name === 'undefined') {
    throw new Error('Breadcrumb construction error, router not consist name parameters')
  }
  if (isCustom) {
    result.custom = true
  }
  return result
}
export default {
  /**
   *
   * @param state
   * @param {{fullpath, matched, meta}}router
   */
  [SYSTEM_BREADCRUMB_MUTATION_SET_LIST] (state, router) {
    const matched = router?.matched
    if (matched) {
      const list = matched.map(item => getBreadcrumbFormatting(item))
      state.list = list.filter(item => item)
    } else {
      state.list = []
    }
  },
  /**
   *
   * @param state
   * @param {{name, meta: {breadcrumb}}}routerItem
   */
  [SYSTEM_BREADCRUMB_MUTATION_ADD_ITEM] (state, routerItem) {
    state.list.push(getBreadcrumbFormatting(routerItem, true))
  }
}
