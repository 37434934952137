import { debugDelay } from '@/lib/utils/env/debug'
import {
  COMMON_LOADER_GETTER_IS_LOADING,
  COMMON_LOADER_MUTATION_TOGGLE_LOADING
} from '@/store/modules/common/loader/common.loader.constant'
import {
  GEO_COMPANIES_ACTION_FETCH,
  GEO_COMPANIES_MUTATION_ADD_ITEMS, GEO_COMPANIES_GETTER_IS_REGION_LOADED, GEO_COMPANIES_MUTATION_ADD_REGION_LOADED
} from '@/store/modules/geo/companies/geoCompanies.constant'

export default {
  async [GEO_COMPANIES_ACTION_FETCH] ({ commit, getters }, payload) {
    if (getters[COMMON_LOADER_GETTER_IS_LOADING]) {
      return false
    }

    if (getters[GEO_COMPANIES_GETTER_IS_REGION_LOADED](payload)) {
      return false
    }
    const mockData = require('@/mock/SectionMapCompanysMarkers.json')

    commit(COMMON_LOADER_MUTATION_TOGGLE_LOADING, true)
    const request = await debugDelay(mockData)
    commit(COMMON_LOADER_MUTATION_TOGGLE_LOADING, false)

    commit(GEO_COMPANIES_MUTATION_ADD_ITEMS, request)
    commit(GEO_COMPANIES_MUTATION_ADD_REGION_LOADED, payload)
  }
}
