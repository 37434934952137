<template>
  <div id="app" class="app">
    <base-loader isGlobal
      v-show="isLoading"
    ></base-loader>

    <router-view/>

    <base-on-top-button/>
  </div>
</template>

<style lang="scss" src="@/assets/scss/index.scss"/>
<script>
import BaseLoader from '@/components/base/loader/BaseLoader'
import BaseOnTopButton from '@/components/base/onTopButton/BaseOnTopButton'
// import SectionEvents from '@/components/section/SectionEvents'
// import SectionFooter from '@/components/section/SectionFooter'
// import SectionHead from '@/components/section/SectionHead'
// import SectionMain from '@/components/section/SectionMain'
import { GEO_ACTION_REQUEST_CITY_BY_IP } from '@/store/modules/geo/geo.constant'
import { SYSTEM_LOADING_GETTER_IS_LOADING } from '@/store/modules/system/loading/systemLoading.constant'
import { STORE_PATH_GEO, STORE_PATH_SYSTEM_LOADING } from '@/store/store.list'
import { mapActions, mapGetters } from 'vuex'

export default {
  metaInfo () {
    return {
      title: this.$t(this.$route.meta.title)
    }
  },
  // metaInfo: {
  //   title: name
  // },
  components: {
    BaseLoader,
    BaseOnTopButton
    // SectionMain,
  },
  methods: {
    ...mapActions(STORE_PATH_GEO, [GEO_ACTION_REQUEST_CITY_BY_IP])
  },
  computed: {
    ...mapGetters(STORE_PATH_SYSTEM_LOADING, [SYSTEM_LOADING_GETTER_IS_LOADING]),
    isLoading () {
      return this[SYSTEM_LOADING_GETTER_IS_LOADING]
    }
  },
  async mounted () {
    // await this[GEO_ACTION_REQUEST_USER_IP]()
    await this[GEO_ACTION_REQUEST_CITY_BY_IP]()
    // await apiGeoGetIpUser()
  },
  created () {
    // this.$nextTick(() => {
    //   console.log(this.$route.meta.title, 'title')
    //   this.$meta.title = this.$t(this.$route.meta.title)
    // })
  }
}
</script>
