import { BACKGROUNDS_MAIN_MUTATION_SET_MAIN_SLIDE_BG } from '@/store/modules/backgrounds/main/backgroundsMain.constant'

export default {
  /**
   *
   * @param state
   * @param {array} values
   */
  [BACKGROUNDS_MAIN_MUTATION_SET_MAIN_SLIDE_BG]: (state, values) => {
    state.mainSliderBackgrounds = values
  }
}
