import { apiGetEduInstitutions } from '~/api/ke'
// import { debugDelay } from '@/lib/utils/env/debug'
import {
  COMMON_LOADER_MUTATION_TOGGLE_LOADING,
  COMMON_LOADER_GETTER_IS_LOADING
} from '@/store/modules/common/loader/common.loader.constant'
import {
  INSTITUTIONS_ACTION_FETCH,
  INSTITUTIONS_MUTATION_ADD_ITEMS
} from '@/store/modules/institutions/institutions.constant'

export default {
  async [INSTITUTIONS_ACTION_FETCH] ({ commit, getters }, payload) {
    if (getters[COMMON_LOADER_GETTER_IS_LOADING]) {
      return false
    }
    commit(COMMON_LOADER_MUTATION_TOGGLE_LOADING, true)
    // const mock = require('@/mock/SectionInstitutions.json')
    // const item = await debugDelay(mock)
    const institutions = await apiGetEduInstitutions()
    commit(COMMON_LOADER_MUTATION_TOGGLE_LOADING, false)
    commit(INSTITUTIONS_MUTATION_ADD_ITEMS, institutions)
  }
}
