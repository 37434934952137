import {
  KINDEREDU_POPULAR_INSTITUTIONS_GETTER_GET_LIST,
  KINDEREDU_POPULAR_INSTITUTIONS_GETTER_GET_PAGE
} from '@/store/modules/kinderedu/popular-institutions/kndereduPopularInstitutions.constant'

export default {
  [KINDEREDU_POPULAR_INSTITUTIONS_GETTER_GET_LIST] (state) {
    return state.item.values
  },
  [KINDEREDU_POPULAR_INSTITUTIONS_GETTER_GET_PAGE] (state) {
    return state.item.page
  }
}
