import {
  TEACHERS_GETTER_GET_LIMITED_LIST,
  DEFAULT_COUNT_LIMITED_TEACHERS_TO_DISPLAY, TEACHERS_GETTER_GET_LIST, TEACHERS_GETTER_GET_PAGE
} from '@/store/modules/teachers/teachers.constant'

export default {
  [TEACHERS_GETTER_GET_LIMITED_LIST]: (state) => {
    return state.item.values.slice(0, DEFAULT_COUNT_LIMITED_TEACHERS_TO_DISPLAY)
  },
  [TEACHERS_GETTER_GET_LIST]: (state) => {
    return state.item.values
  },
  [TEACHERS_GETTER_GET_PAGE]: (state) => {
    return state.item.page
  }
}
