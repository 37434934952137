import {
  FORMS_ORDER_MUTATION_SET_AGREEMENT,
  FORMS_ORDER_MUTATION_SET_MAIL,
  FORMS_ORDER_MUTATION_SET_PERSON,
  FORMS_ORDER_MUTATION_SET_PHONE,
  FORMS_ORDER_MUTATION_CLEAR_DATA
} from '@/store/modules/forms/order/formsOrder.constant'

export default {
  [FORMS_ORDER_MUTATION_SET_AGREEMENT] (state, value) {
    state.agreement = !!value
  },
  [FORMS_ORDER_MUTATION_SET_MAIL] (state, value) {
    state.email = value
  },
  [FORMS_ORDER_MUTATION_SET_PERSON] (state, value) {
    state.person = value
  },
  [FORMS_ORDER_MUTATION_SET_PHONE] (state, value) {
    state.phone = value
  },
  [FORMS_ORDER_MUTATION_CLEAR_DATA] (state) {
    state.agreement = false
    state.email = ''
    state.person = ''
    state.phone = ''
  }
}
