import { apiGeoGetIpUser, apiGeoGetCityByIp } from '@/lib/api/geo/apiGeo'
import { C_LOCAL_STORAGE_GEO_USER } from '@/lib/constant/c.localStorage'
import { C_REQUEST_CODE_SERVICE_UNAVAILABLE } from '@/lib/constant/c.request'
import $Storage from '@/lib/utils/$Storage'
import {
  GEO_ACTION_REQUEST_USER_IP,
  GEO_ACTION_REQUEST_CITY_BY_IP
  , GEO_MUTATION_SET_GEO_DEFAULT, GEO_MUTATION_SET_GEO_STORED
} from '@/store/modules/geo/geo.constant'

export default {
  async [GEO_ACTION_REQUEST_USER_IP] () {
    const request = await apiGeoGetIpUser()
    if (request.msgCode === C_REQUEST_CODE_SERVICE_UNAVAILABLE) {
      return false
    } else {
      const { result } = request
      return result?.ip
    }
  },

  async [GEO_ACTION_REQUEST_CITY_BY_IP]  ({ dispatch, commit }, value = undefined) {
    const geoData = $Storage.get(C_LOCAL_STORAGE_GEO_USER)
    if (typeof geoData !== 'undefined') {
      commit(GEO_MUTATION_SET_GEO_STORED, geoData)
      return true
    }
    let ip = value
    if (ip === undefined) {
      ip = await dispatch(GEO_ACTION_REQUEST_USER_IP)
    }
    if (ip === false) {
      console.log('implaiment: дефолт значения для города и прочего')
      // todo: тут нужно сделать дефолт значение для города, там москва или чтот еще
      return false
    }
    const geoRequest = await apiGeoGetCityByIp(ip)
    if (geoRequest) {
      commit(GEO_MUTATION_SET_GEO_DEFAULT, geoRequest)
    }
    return true
    // console.log('actions', ip)
  }
}
