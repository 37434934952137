import { FS } from '~/common/constants/services'
import { factoryRequestSender } from '~/common/utils'

const DEFAULT_FILE_FIELDS = 'caption,name,contentTypeId,bucket,url,state,size,placement'

export const apiGetFiles = async (ids, fields = DEFAULT_FILE_FIELDS) => {
  const query = { ids, fields }
  const send = factoryRequestSender(FS, 'getFiles', { query })
  const { result } = await send()
  return result
}
