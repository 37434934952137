import { isProcessDevelop } from '@/lib/utils/env/process'
import article from '@/store/modules/article/article'
import articles from '@/store/modules/articles/articles'
import backgrounds from '@/store/modules/backgrounds/backgrounds'
import documents from '@/store/modules/documents/documents'
import events from '@/store/modules/events/events'
import forms from '@/store/modules/forms/forms'
import geo from '@/store/modules/geo/geo'
import institutions from '@/store/modules/institutions/institutions'
import kinderedu from '@/store/modules/kinderedu/kinderedu'
import modals from '@/store/modules/modals/modals'
import promo from '@/store/modules/promo/promo'
import reviews from '@/store/modules/reviews/reviews'
import system from '@/store/modules/system/system'
import teachers from '@/store/modules/teachers/teachers'
import {
  STORE_NAME_EVENTS,
  STORE_NAME_SYSTEM,
  STORE_NAME_DOCUMENTS,
  STORE_NAME_INSTITUTIONS,
  STORE_NAME_TEACHERS,
  STORE_NAME_REVIEWS,
  STORE_NAME_PROMO,
  STORE_NAME_GEO,
  STORE_NAME_MODALS,
  STORE_NAME_BACKGROUNDS,
  STORE_NAME_ARTICLE, STORE_NAME_FORMS, STORE_NAME_ARTICLES, STORE_NAME_KINDEREDU
} from '@/store/store.list'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: isProcessDevelop,
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    [STORE_NAME_ARTICLE]: article,
    [STORE_NAME_ARTICLES]: articles,
    [STORE_NAME_SYSTEM]: system,
    [STORE_NAME_EVENTS]: events,
    [STORE_NAME_DOCUMENTS]: documents,
    [STORE_NAME_INSTITUTIONS]: institutions,
    [STORE_NAME_TEACHERS]: teachers,
    [STORE_NAME_REVIEWS]: reviews,
    [STORE_NAME_PROMO]: promo,
    [STORE_NAME_GEO]: geo,
    [STORE_NAME_MODALS]: modals,
    [STORE_NAME_BACKGROUNDS]: backgrounds,
    [STORE_NAME_FORMS]: forms,
    [STORE_NAME_KINDEREDU]: kinderedu
  }
})
