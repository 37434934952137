import { DEFAULT_LIMITED_TO_DISPLAY } from '@/lib/constant/c.system'

export const SECTION_DOCUMENTS_TYPE = {
  EDUCATION_INSTITUTIONS: 'educationInstitutions',
  TEACHERS: 'teachers'
}

export const DEFAULT_COUNT_LIMITED_DOCUMENTS_TO_DISPLAY = DEFAULT_LIMITED_TO_DISPLAY

export const DOCUMENTS_MUTATION_SET_SELECTED_TYPE = 'documents_mutation_set_selected_type'
export const DOCUMENTS_MUTATION_ADD_ITEMS_IN_SELECTED_TYPE = 'documents_mutation_add_item_in_selected_type'

export const DOCUMENTS_GETTER_GET_SECTION_LOADER = 'documents_getter_get_section_loader'
export const DOCUMENTS_GETTER_GET_LIST_BY_SELECTED_TYPE = 'documents_getter_get_list_by_selected_type'
export const DOCUMENTS_GETTER_GET_LIMITED_LIST_BY_SELECTED_TYPE = 'documents_getter_get_limited_list_by_selected_type'
export const DOCUMENTS_GETTER_GET_PAGE_BY_SELECTED_TYPE = 'documents_getter_get_page_by_selected_type'

export const DOCUMENTS_ACTION_FETCH = 'document_action_fetch'
