import { isProcessProduct } from '@/lib/utils/env/process'
import VueI18n from 'vue-i18n'
import { C_LOCALS_DEFAULT_LOCALE_NAME, C_LOCALS_DATE_FORMAT_TYPE } from '@/lib/constant/c.locales'
import { C_LOCAL_STORAGE_LOCALE_LIST, C_LOCAL_STORAGE_LOCALE_LANG } from '@/lib/constant/c.localStorage'
import $Storage from '@/lib/utils/$Storage'
import { debugDelay } from '@/lib/utils/env/debug'
import { getOneDay } from '@/lib/utils/utilsDateTime'
import MockLocale from '@/mock/locale.json'
import { set as _set } from 'lodash'

/**
 * Сериализует полученный объект с сылками
 * через точки в подходящий для i18n vue объект
 * @param i18nData
 * @return {{}}
 */
function serializeI18N (i18nData = {}) {
  const serializable = {}

  for (const keyItem in i18nData) {
    _set(serializable, keyItem, i18nData[keyItem])
  }
  return serializable
}

async function locales () {
  if (isProcessProduct) {
    const getI18N = $Storage.get(C_LOCAL_STORAGE_LOCALE_LIST)
    const getSelectedLang = $Storage.get(C_LOCAL_STORAGE_LOCALE_LANG)
    if (getI18N && getSelectedLang) {
      return { [getSelectedLang]: getI18N }
    }
  }
  // Если в локалах пустота то делаем всякое)
  // todo: запрос к базе
  const requestedI18N = await debugDelay(MockLocale, 100)
  const selectedLang = navigator.language || C_LOCALS_DEFAULT_LOCALE_NAME
  $Storage.set(C_LOCAL_STORAGE_LOCALE_LANG, selectedLang)
  const serializableI18N = serializeI18N(requestedI18N)
  $Storage.set(C_LOCAL_STORAGE_LOCALE_LIST, serializableI18N, getOneDay())

  return { [selectedLang]: serializableI18N }
}

/**
 *
 * @return {Promise<VueI18n>}
 */
const getI18N = async () => {
  const messages = await locales()
  const dateTimeFormats = {
    ru: {
      [C_LOCALS_DATE_FORMAT_TYPE.numericShort]: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      [C_LOCALS_DATE_FORMAT_TYPE.short]: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      [C_LOCALS_DATE_FORMAT_TYPE.long]: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric'
      },
      [C_LOCALS_DATE_FORMAT_TYPE.full]: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }
    }
  }
  const pluralizationRules = {
    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    ru: function (choice, choicesLength) {
      // this === VueI18n instance, so the locale property also exists here

      if (choice === 0) {
        return 0
      }

      const teen = choice > 10 && choice < 20
      const endsWithOne = choice % 10 === 1

      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2
      }
      if (!teen && endsWithOne) {
        return 1
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2
      }

      return (choicesLength < 4) ? 2 : 3
    }
  }
  return new VueI18n({
    dateTimeFormats,
    pluralizationRules,
    locale: $Storage.get(C_LOCAL_STORAGE_LOCALE_LANG) || C_LOCALS_DEFAULT_LOCALE_NAME,
    messages
  })
}

/**
 * Получает локализацию, и возвращает её (паралельно записывая её в локалсторадж.
 * Ко всем запросам такого типа должен идти какойнить forceUpdate
 * который чекает нужно ли заменить эти локалсторадж данные
 * @return {Promise<{'ru-RU': {}}|{'ru-RU': *}>}
 */
export default getI18N
