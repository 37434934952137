import actions from '@/store/modules/documents/actions'
import { SECTION_DOCUMENTS_TYPE } from '@/store/modules/documents/documents.constant'
import mutations from '@/store/modules/documents/mutations'
import getters from '@/store/modules/documents/getters'
import documentsInstitutions from '@/store/modules/documents/type/documentsInstitutions'
import documentsTeacher from '@/store/modules/documents/type/documentsTeacher'

export default {
  namespaced: true,
  modules: {
    [SECTION_DOCUMENTS_TYPE.EDUCATION_INSTITUTIONS]: documentsInstitutions,
    [SECTION_DOCUMENTS_TYPE.TEACHERS]: documentsTeacher
  },
  state: {
    selectedType: SECTION_DOCUMENTS_TYPE.EDUCATION_INSTITUTIONS
  },
  actions,
  mutations,
  getters
}
