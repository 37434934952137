import {
  EVENTS_MUTATION_SET_SELECTED_TYPE,
  EVENTS_MUTATION_ADD_ITEMS_IN_SELECTED_TYPE
} from '@/store/modules/events/events.constant'

export default {
  /**
   * @param state
   * @param {SECTION_EVENT_TYPE} value
   * @constructor
   */
  [EVENTS_MUTATION_SET_SELECTED_TYPE]: (state, value) => {
    state.selectedType = value
  },
  /**
   *
   * @param state
   * @param {Object} payload
   * @param {Array} payload.values
   * @param {SECTION_EVENT_TYPE} payload.type
   */
  [EVENTS_MUTATION_ADD_ITEMS_IN_SELECTED_TYPE]: (state, payload) => {
    const { values, type = state.selectedType } = payload
    state[type].item.values.push(...values)
    state[type].item.page++
  }

}
