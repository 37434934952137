<template>
  <i class="icon icon-portal"
     :class="`icon-${icon}`"/>
</template>

<script>
export default {
  name: 'BaseIcon',
  props: {
    icon: [String, Number]
  }
}
</script>

<style scoped>

</style>
