
export const DEFAULT_COUNT_LIMITED_PROMOS_TO_DISPLAY = 4

export const PROMO_GETTER_GET_LIST = 'promo_getter_get_list'
export const PROMO_GETTER_GET_LIMITED_LIST = 'promo_getter_get_limited_list'
export const PROMO_GETTER_GET_PAGE = 'promo_getter_get_page'

export const PROMO_MUTATION_ADD_ITEMS = 'promo_mutation_add_items'

export const PROMO_ACTION_FETCH = 'promo_action_fetch'
