import commonLoader from '@/store/modules/common/loader/commonLoader?state-reviews-parents-about-institutions'
export default {
  namespaced: true,
  modules: {
    commonLoader
  },
  state: {
    item: {
      values: [],
      page: 0
    }
  }
}
