export const C_LOCALS_DEFAULT_LOCALE_NAME = 'ru-RU'

/**
 * @example
 * numericShort: 17.10.2020
 * short: 17 окт. 2020 г.
 * long: чт. 17 сент. 2020 г,, 01:18
 * full: 17 сентября 2020 г., 01:18
 * @type {{numericShort: string, short: string, long: string, full: string}}
 */
export const C_LOCALS_DATE_FORMAT_TYPE = {
  numericShort: 'numericShort',
  short: 'short',
  long: 'long',
  full: 'full'
}

/**
 *
 * @type {{B: string, MB: string, KB: string, GB: string, TB: string}}
 */
export const C_LOCALS_FILE_SIZE_PLURALISATION_TYPE = {
  B: 'B',
  KB: 'KB',
  MB: 'MB',
  GB: 'GB',
  TB: 'TB'
}

/**
 *
 * @param {String<C_LOCALS_FILE_SIZE_PLURALISATION_TYPE>} type
 * @return {*}
 * @constructor
 */
export const C_LOCALS_FILE_SIZE_PLURALISATION = (type) => {
  return `PORTAL.DEFAULTS.FILE-SIZE.${type}`
}
