export const C_CONTACTS_TYPE_URL = 70
export const C_CONTACTS_TYPE_MOBILE_HOME = 1
export const C_CONTACTS_TYPE_MOBILE_OFFICE = 2
export const C_CONTACTS_TYPE_STATION_HOME = 3
export const C_CONTACTS_TYPE_STATION_OFFICE = 4
export const C_CONTACTS_TYPE_FACEBOOK = 20
export const C_CONTACTS_TYPE_OK = 21
export const C_CONTACTS_TYPE_VK = 22
export const C_CONTACTS_TYPE_TWITTER = 23
export const C_CONTACTS_TYPE_INSTAGRAM = 24
export const C_CONTACTS_TYPE_TELEGRAM = 40
export const C_CONTACTS_TYPE_WHATSAPP = 41
export const C_CONTACTS_TYPE_VIBER = 42
export const C_CONTACTS_TYPE_ACTUAL_ADDRESS = 60
export const C_CONTACTS_TYPE_LEGAL_ADDRESS = 61
export const C_CONTACTS_TYPE_POSTAL_ADDRESS = 62
export const C_CONTACTS_TYPE_OFFICE_EMAIL = 91
export const C_CONTACTS_TYPE_HOME_EMAIL = 90
