import { apiCompileResult } from '@/lib/api/apiUtils'
import { debugDelay } from '@/lib/utils/env/debug'
import {
  ARTICLES_POPULAR_ACTION_FETCH,
  ARTICLES_POPULAR_MUTATIONS_ADD_ITEMS
} from '@/store/modules/articles/popular/articlesPopular.constant'
import { COMMON_LOADER_MUTATION_TOGGLE_LOADING } from '@/store/modules/common/loader/common.loader.constant'
import { COMMON_REQUESTED_GETTER_IS_REQUEST_ERROR } from '@/store/modules/common/requested/commot.requested.constant'

export default {

  /**
   *
   * @param commit
   * @param getters
   * @param {{type: string}} payload
   */
  async [ARTICLES_POPULAR_ACTION_FETCH] ({ commit, getters }, payload) {
    commit(COMMON_LOADER_MUTATION_TOGGLE_LOADING, true)
    const mockData = require('@/mock/articles/popular/ArticlesPopular.json')
    const request = debugDelay(mockData, 4000)
    const response = await apiCompileResult(request, commit)
    commit(COMMON_LOADER_MUTATION_TOGGLE_LOADING, false)
    if (getters[COMMON_REQUESTED_GETTER_IS_REQUEST_ERROR]) {
      console.error('Не удалось получить список популярных статей')
      return false
    }
    commit(ARTICLES_POPULAR_MUTATIONS_ADD_ITEMS, response)
  }

}
