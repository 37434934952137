import { DEFAULT_LIMITED_TO_DISPLAY } from '@/lib/constant/c.system'

export const DEFAULT_COUNT_LIMITED_TEACHERS_TO_DISPLAY = DEFAULT_LIMITED_TO_DISPLAY

export const TEACHERS_GETTER_GET_LIST = 'teachers_getter_get_list'
export const TEACHERS_GETTER_GET_LIMITED_LIST = 'teachers_getter_get_limited_list'
export const TEACHERS_GETTER_GET_PAGE = 'teachers_getter_get_page'

export const TEACHERS_MUTATIONS_ADD_ITEMS = 'teachers_mutations_add_items'

export const TEACHERS_ACTION_FETCH = 'teachers_action_fetch'
