import {
  ARTICLES_POPULAR_GETTER_GET_LIMITED_LIST,
  ARTICLES_POPULAR_GETTER_GET_LIST, ARTICLES_POPULAR_GETTER_GET_PAGE
} from '@/store/modules/articles/popular/articlesPopular.constant'

export default {
  [ARTICLES_POPULAR_GETTER_GET_LIMITED_LIST]: state => (limit = 6) => {
    return state.item.values.slice(0, limit)
  },
  [ARTICLES_POPULAR_GETTER_GET_LIST]: state => {
    return state.item.values
  },
  [ARTICLES_POPULAR_GETTER_GET_PAGE]: state => {
    return state.page
  }
}
