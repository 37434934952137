import { apiGetResources } from '~/api/ke'
// import { debugDelay } from '@/lib/utils/env/debug'
import { COMMON_LOADER_MUTATION_TOGGLE_LOADING } from '@/store/modules/common/loader/common.loader.constant'
import {
  SECTION_EVENT_TYPE,
  // EVENTS_MUTATION_SET_SELECTED_TYPE,
  EVENTS_MUTATION_ADD_ITEMS_IN_SELECTED_TYPE, EVENTS_ACTION_FETCH, EVENTS_GETTER_GET_SECTION_LOADER
} from '@/store/modules/events/events.constant'

export default {
  /**
   *
   * @param commit
   * @param getter
   * @param {{type: SECTION_EVENT_TYPE, page: Number}}payload
   * @return {Promise<boolean|void>}
   * @constructor
   */
  async [EVENTS_ACTION_FETCH] ({ commit, getters }, payload) {
    if (getters[EVENTS_GETTER_GET_SECTION_LOADER](payload.type)) {
      return false
    }

    commit(`${payload.type}/${COMMON_LOADER_MUTATION_TOGGLE_LOADING}`, true)

    // Секция для Педагогов пока еще не готова
    const resources = await (payload.type === SECTION_EVENT_TYPE.TEACHERS ? [] : apiGetResources())

    // const mockAssociate = {
    //   [SECTION_EVENT_TYPE.EDUCATION_INSTITUTIONS]: require('@/mock/EventInstitutions.json'),
    //   [SECTION_EVENT_TYPE.TEACHERS]: require('@/mock/EventTeachers.json')
    // }

    // const mockAssociate = {
    //   [SECTION_EVENT_TYPE.EDUCATION_INSTITUTIONS]: resources,
    //   [SECTION_EVENT_TYPE.TEACHERS]: require('@/mock/EventTeachers.json')
    // }
    // commit(`${payload.type}/${COMMON_LOADER_MUTATION_TOGGLE_LOADING}`, true)
    // const item = await debugDelay(mockAssociate[payload.type], 3500)
    // const item = mockAssociate[payload.type]
    commit(`${payload.type}/${COMMON_LOADER_MUTATION_TOGGLE_LOADING}`, false)
    // commit(EVENTS_MUTATION_SET_SELECTED_TYPE, payload.type)
    commit(EVENTS_MUTATION_ADD_ITEMS_IN_SELECTED_TYPE, {
      values: resources,
      type: payload.type
    })
  }
}
