import { factoryRequest } from '@/lib/api/apiUtils'
import { C_REQUEST_METHODS } from '@/lib/constant/c.request'
import { isProcessDevelop } from '@/lib/utils/env/process'

export const apiGeoGetIpUser = async () => {
  // todo: девелоп заглушка для получения города (так как в локале ip всегда ::1
  if (isProcessDevelop) {
    return { result: { ip: '212.3.130.218' } }
  }
  return await factoryRequest(C_REQUEST_METHODS.GET, 'http://localhost:3000/login/ip')()
}

export const apiGeoGetCityByIp = async (ip) => {
  const token = 'a8f8ddccd84ee7750d686fd35061ca4d611f8745'
  const request = await factoryRequest(C_REQUEST_METHODS.GET,
    'https://suggestions.dadata.ru/suggestions/api/4_1/rs/iplocate/address',
    false, { Authorization: 'token ' + token })({ ip })
  return request?.location
}
