import { apiGetDocuments } from '~/api/ke'
// import { debugDelay } from '@/lib/utils/env/debug'
import { COMMON_LOADER_MUTATION_TOGGLE_LOADING } from '@/store/modules/common/loader/common.loader.constant'
import {
  DOCUMENTS_ACTION_FETCH,
  DOCUMENTS_GETTER_GET_SECTION_LOADER,
  SECTION_DOCUMENTS_TYPE,
  DOCUMENTS_MUTATION_ADD_ITEMS_IN_SELECTED_TYPE
} from '@/store/modules/documents/documents.constant'

export default {
  /**
   *
   * @param commit
   * @param getter
   * @param {{type: SECTION_DOCUMENTS_TYPE, page: Number}}payload
   * @return {Promise<boolean|void>}
   * @constructor
   */
  async [DOCUMENTS_ACTION_FETCH] ({ commit, getters }, payload) {
    if (getters[DOCUMENTS_GETTER_GET_SECTION_LOADER](payload.type)) {
      return false
    }

    commit(`${payload.type}/${COMMON_LOADER_MUTATION_TOGGLE_LOADING}`, true)

    // Секция для Педагогов пока еще не готова
    const documents = await (payload.type === SECTION_DOCUMENTS_TYPE.TEACHERS ? [] : apiGetDocuments())

    // const mockAssociate = {
    //   [SECTION_DOCUMENTS_TYPE.EDUCATION_INSTITUTIONS]: documents,
    //   [SECTION_DOCUMENTS_TYPE.TEACHERS]: require('@/mock/SectionDocumentsTeacher.json')
    // }
    // const mockAssociate = {
    //   [SECTION_DOCUMENTS_TYPE.EDUCATION_INSTITUTIONS]: require('@/mock/SectionDocumentsInstitutions.json'),
    //   [SECTION_DOCUMENTS_TYPE.TEACHERS]: require('@/mock/SectionDocumentsTeacher.json')
    // }
    commit(`${payload.type}/${COMMON_LOADER_MUTATION_TOGGLE_LOADING}`, true)
    // const item = await debugDelay(mockAssociate[payload.type], 3500)
    // const item = mockAssociate[payload.type]
    commit(`${payload.type}/${COMMON_LOADER_MUTATION_TOGGLE_LOADING}`, false)
    commit(DOCUMENTS_MUTATION_ADD_ITEMS_IN_SELECTED_TYPE, {
      values: documents,
      type: payload.type
    })
  }
}
