import {
  ARTICLE_MUTATION_SET_ARTICLE_VALUE,
  ARTICLE_MUTATION_CLEAR_VALUE
} from '@/store/modules/article/article.constant'

export default {
  /**
   *
   * @param state
   * @param {{member: {}, title, content, id}}value
   */
  [ARTICLE_MUTATION_SET_ARTICLE_VALUE] (state, value) {
    state.value = value
  },
  [ARTICLE_MUTATION_CLEAR_VALUE] (state) {
    state.value = undefined
  }
}
