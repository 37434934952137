import { debugDelay } from '@/lib/utils/env/debug'
import { ARTICLE_ACTION_FETCH, ARTICLE_MUTATION_SET_ARTICLE_VALUE } from '@/store/modules/article/article.constant'
import { SYSTEM_LOADING_MUTATION_TOGGLE_LOADING } from '@/store/modules/system/loading/systemLoading.constant'
import { STORE_PATH_SYSTEM_LOADING } from '@/store/store.list'

export default {
  /**
   *
   * @param commit
   * @param getters
   * @param rootCommit
   * @param {{type: string, id:number}} payload
   */
  async [ARTICLE_ACTION_FETCH] ({ commit }, payload) {
    commit(`${STORE_PATH_SYSTEM_LOADING}/${SYSTEM_LOADING_MUTATION_TOGGLE_LOADING}`, true, { root: true })
    const mockData = require('@/mock/articles/ArticleDefaultItem.json')
    try {
      const result = await debugDelay(mockData, 500)
      commit(ARTICLE_MUTATION_SET_ARTICLE_VALUE, result)
    } catch (e) {

    }

    commit(`${STORE_PATH_SYSTEM_LOADING}/${SYSTEM_LOADING_MUTATION_TOGGLE_LOADING}`, false, { root: true })
    // rootMutations[`${STORE_PATH_SYSTEM_LOADING}/${SYSTEM_LOADING_MUTATION_TOGGLE_LOADING}`](true)
  }
}
