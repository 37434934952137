import {
  MODALS_INSTITUTION_MUTATION_ADD_ITEM_IN_LOADED_LIST,
  MODALS_INSTITUTION_MUTATION_SET_ACTIVE_ITEM, MODALS_INSTITUTION_MUTATION_CLEAR_ACTIVE_ITEM
} from '@/store/modules/modals/institution/modalsInstitutions.constant'

export default {
  [MODALS_INSTITUTION_MUTATION_CLEAR_ACTIVE_ITEM]: (state) => {
    state.activeItem = {}
  },

  [MODALS_INSTITUTION_MUTATION_ADD_ITEM_IN_LOADED_LIST]: (state, payload) => {
    if (state.loadedList.findIndex(item => item.id === payload.id)) {
      state.loadedList.push(payload)
    }
  },
  [MODALS_INSTITUTION_MUTATION_SET_ACTIVE_ITEM]: (state, id) => {
    state.activeItem = state.loadedList.find(item => item.id === id)
  }
}
