import {
  SYSTEM_GETTER_GET_RESOLUTION_TYPE,
  SYSTEM_GETTER_CHECK_RESOLUTION_TYPE,
  SYSTEM_GETTER_GET_NORMATIVE_DOCUMENTS_LIST
} from '@/store/modules/system/system.constant'

export default {
  [SYSTEM_GETTER_GET_RESOLUTION_TYPE]: (state) => {
    return state.resolutionType
  },
  /**
   *
   * @param state
   * @return {function(Number): boolean}
   */
  [SYSTEM_GETTER_CHECK_RESOLUTION_TYPE]: (state) => (width) => {
    return state.resolutionType <= width
  },
  [SYSTEM_GETTER_GET_NORMATIVE_DOCUMENTS_LIST]: (state) => {
    return state.normativeDocumentList?.documents || []
  }

}
