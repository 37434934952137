import actions from '@/store/modules/backgrounds/main/actions'
import getters from '@/store/modules/backgrounds/main/getters'
import mutations from '@/store/modules/backgrounds/main/mutations'
import commonLoader from '@/store/modules/common/loader/commonLoader?backgrounds-module'

export default {
  namespaced: true,
  state: {
    mainSliderBackgrounds: []
  },
  modules: {
    commonLoader
  },
  getters,
  mutations,
  actions
}
