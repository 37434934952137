import sa from 'superagent'
import { getEndpointOptions } from './endpoint'
import { HTTP, TOKENS } from '~/common/constants'
import { parseResponseSuccess, parseResponseError } from './response'

const { HEADER_NAMES } = HTTP
const { KINDEREDU_PORTAL_TOKEN } = TOKENS

// import { successProcess, errorProcess } from '~/common/utils/xService'
// import { getApplicationAccessToken, getBearerAccessToken } from '~/common/utils/tokens'

export const factoryRequest = (method, url, credentials = false, headers = {}) => {
  const fnRequest = sa[method.toLowerCase()]
  if (!(fnRequest instanceof Function)) {
    throw new Error(`Method of "${method}" does not exists!`)
  }

  const request = fnRequest(url)

  // Токен приложения
  request.set(HEADER_NAMES.X_APPLICATION_ACCESS_TOKEN, KINDEREDU_PORTAL_TOKEN)

  // request.set(APP_ACCESS_TOKEN, getApplicationAccessToken())

  // if (credentials) {
  //   // Токен авторизованного пользователя
  //   request.set(BEARER_ACCESS_TOKEN, getBearerAccessToken())
  // }

  // Добавляем входящие заголовки -- если есть
  for (const name of Object.keys(headers)) {
    request.set(name, headers[name])
  }

  return payload => {
    const pRequest = payload ? request.send(payload) : request
    return pRequest.then(parseResponseSuccess).catch(parseResponseError)
  }
}

export const factoryRequestSender = (service, path, options = {}) => {
  const { method, url } = getEndpointOptions(service, path, options)
  return factoryRequest(method, url)
}
