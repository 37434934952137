import { MODALS_GETTERS_IS_OPEN, MODALS_GETTERS_IS_NESTED } from '@/store/modules/modals/modals.constant'

export default {
  [MODALS_GETTERS_IS_OPEN]: (state) => {
    return state.isOpen
  },
  [MODALS_GETTERS_IS_NESTED]: (state) => {
    return state.nested > 1
  }
}
