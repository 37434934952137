import {
  ARTICLE_GETTER_GET_MEMBER,
  ARTICLE_GETTER_IS_ARTICLE,
  ARTICLE_GETTER_GET_TITLE, ARTICLE_GETTER_GET_VIEWS, ARTICLE_GETTER_GET_DATE_TIME_INSERT, ARTICLE_GETTER_GET_CONTENT
} from '@/store/modules/article/article.constant'

export default {
  [ARTICLE_GETTER_GET_MEMBER] (state) {
    return state.value?.member
  },
  [ARTICLE_GETTER_IS_ARTICLE] (state) {
    return typeof state.value === 'object'
  },
  [ARTICLE_GETTER_GET_TITLE] (state) {
    return state.value?.title
  },
  [ARTICLE_GETTER_GET_VIEWS] (state) {
    return state.value?.views
  },
  [ARTICLE_GETTER_GET_DATE_TIME_INSERT] (state) {
    return state.value?.proto?.insert?.value
  },
  [ARTICLE_GETTER_GET_CONTENT] (state) {
    return state.value?.content
  }
}
