export const ARTICLE_MUTATION_SET_ARTICLE_VALUE = 'article_mutation_set_article_value'
export const ARTICLE_MUTATION_CLEAR_VALUE = 'article_mutation_clear_value'

export const ARTICLE_ACTION_FETCH = 'article_action_fetch'

export const ARTICLE_GETTER_GET_MEMBER = 'article_getter_get_member'
export const ARTICLE_GETTER_IS_ARTICLE = 'article_getter_is_article'
export const ARTICLE_GETTER_GET_TITLE = 'article_getter_get_title'
export const ARTICLE_GETTER_GET_VIEWS = 'article_getter_get_views'
export const ARTICLE_GETTER_GET_DATE_TIME_INSERT = 'article_getter_get_date_time_insert'
export const ARTICLE_GETTER_GET_CONTENT = 'article_getter_get_content'
