import { DEFAULT_LIMITED_TO_DISPLAY } from '@/lib/constant/c.system'

export const SECTION_EVENT_TYPE = {
  EDUCATION_INSTITUTIONS: 'educationInstitutions',
  TEACHERS: 'teachers'
}

export const DEFAULT_COUNT_LIMITED_EVENTS_TO_DISPLAY = DEFAULT_LIMITED_TO_DISPLAY

export const EVENTS_MUTATION_SET_SELECTED_TYPE = 'events_mutation_set_selected_type'
export const EVENTS_MUTATION_ADD_ITEMS_IN_SELECTED_TYPE = 'events_mutation_add_items_in_selected_type'

export const EVENTS_GETTER_GET_SECTION_LOADER = 'events_getter_get_section_loader'
export const EVENTS_GETTER_GET_LIST_BY_SELECTED_TYPE = 'events_getter_get_list_by_selected_type'
export const EVENTS_GETTER_GET_LIMITED_LIST_BY_SELECTED_TYPE = 'events_getter_get_limited_list_by_selected_type'
export const EVENTS_GETTER_GET_PAGE_BY_SELECTED_TYPE = 'events_getter_get_page_by_selected_type'

export const EVENTS_ACTION_FETCH = 'events_action_fetch'
