import {
  C_ROUTER_NAME_INDEX,
  C_ROUTER_NAME_ROOT_ARTICLE,
  C_ROUTER_NAME_ARTICLE_ITEM,
  C_ROUTER_NAME_PROMOTION_PAGE_KINDEREDU
} from '@/lib/constant/c.router'
import Vue from 'vue'
import VueRouter from 'vue-router'
const PageIndex = () => import(/* webpackChunkName: "home" */ '@/views/ViewsIndex')
const PageArticlesDefault = () => import(/* webpackChunkName: "articles" */ '@/views/articles/ViewsArticlesDefault')
const PageArticles = () => import(/* webpackChunkName: "articles" */ '@/views/ViewsArticles')
const PageArticlesIndex = () => import(/* webpackChunkName: "articles" */ '@/views/articles/ViewArticlesList')
const PageRoot = () => import('@/views/ViewsRoot')
const PagePromotionKinderedu = () => import('@/views/promotion/ViewPromotionKinderedu.vue')
Vue.use(VueRouter)

const routes = [
  {
    path: '/promotion/kinderedu',
    name: C_ROUTER_NAME_PROMOTION_PAGE_KINDEREDU,
    meta: {
      title: 'PORTAL.PROMOTION.PAGE.TITLE'
    },
    component: PagePromotionKinderedu
  },
  {
    path: '/',
    redirect: { name: C_ROUTER_NAME_INDEX },
    meta: {
      title: 'PORTAL.ROUTER.INDEX.META.PAGE_TITLE',
      breadcrumb: 'PORTAL.ROUTER.INDEX.BREADCRUMB.VALUE'
    },
    component: PageRoot,
    children: [
      {
        path: '',
        name: C_ROUTER_NAME_INDEX,
        meta: {
          title: 'PORTAL.ROUTER.INDEX.META.PAGE_TITLE'
        },
        component: PageIndex
      },
      {
        path: '/articles',
        redirect: { name: C_ROUTER_NAME_ROOT_ARTICLE },
        meta: {
          title: 'PORTAL.ROUTER.ARTICLE.META.PAGE_TITLE',
          breadcrumb: 'PORTAL.ROUTER.ARTICLE.BREADCRUMB.VALUE'
        },
        component: PageArticles,
        children: [
          {
            path: '',
            name: C_ROUTER_NAME_ROOT_ARTICLE,
            component: PageArticlesIndex,
            meta: {
              title: 'PORTAL.ROUTER.ARTICLE.META.PAGE_TITLE'
            }
          },
          {
            path: ':type/:id',
            meta: {
              title: 'PORTAL.ROUTER.ARTICLE-ITEM.META.PAGE_TITLE',
              breadcrumb: { custom: true }
            },
            name: C_ROUTER_NAME_ARTICLE_ITEM,
            component: PageArticlesDefault
          }
        ]
      }
    ]
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
