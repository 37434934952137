import { apiCompileResult } from '@/lib/api/apiUtils'
import { debugDelay } from '@/lib/utils/env/debug'
import { COMMON_LOADER_MUTATION_TOGGLE_LOADING } from '@/store/modules/common/loader/common.loader.constant'
import { COMMON_REQUESTED_GETTER_IS_REQUEST_ERROR } from '@/store/modules/common/requested/commot.requested.constant'
import {
  KINDEREDU_POPULAR_INSTITUTIONS_ACTION_FETCH,
  KINDEREDU_POPULAR_INSTITUTIONS_GETTER_GET_LIST, KINDEREDU_POPULAR_INSTITUTIONS_MUTATION_ADD_ITEM
} from '@/store/modules/kinderedu/popular-institutions/kndereduPopularInstitutions.constant'

export default {
  async [KINDEREDU_POPULAR_INSTITUTIONS_ACTION_FETCH] ({ commit, getters }) {
    if (getters[KINDEREDU_POPULAR_INSTITUTIONS_GETTER_GET_LIST].length > 0) {
      return true
    }
    commit(COMMON_LOADER_MUTATION_TOGGLE_LOADING, true)
    const mockData = require('@/mock/promotions/kinderedu/PopularKindereduInstitutions.json')
    const request = debugDelay(mockData, 2000)
    const response = await apiCompileResult(request, commit)
    if (getters[COMMON_REQUESTED_GETTER_IS_REQUEST_ERROR]) {
      commit(COMMON_LOADER_MUTATION_TOGGLE_LOADING, false)
      return false
    }
    commit(KINDEREDU_POPULAR_INSTITUTIONS_MUTATION_ADD_ITEM, response)
    commit(COMMON_LOADER_MUTATION_TOGGLE_LOADING, false)
  }
}
