import { C_CONTACTS_TYPE_HOME_EMAIL, C_CONTACTS_TYPE_URL } from '@/lib/constant/c.contacts'
import {
  MODALS_INSTITUTION_GETTER_IS_LOADED_ITEM,
  MODALS_INSTITUTION_GETTER_GET_ACTIVE_ITEM,
  MODALS_INSTITUTION_GETTER_GET_CONTACTS_PHONES,
  MODAL_INSTITUTIONS_CONTACTS_PHONE_TYPE_ID_LIST,
  MODALS_INSTITUTION_GETTER_GET_TITLE,
  MODALS_INSTITUTION_GETTER_GET_LOGO,
  MODALS_INSTITUTION_GETTER_GET_RAW_ADDRESS,
  MODALS_INSTITUTION_GETTER_GET_ABOUT, MODALS_INSTITUTION_GETTER_GET_ACTIVE_EMAIL, MODALS_INSTITUTION_GETTER_GET_URL
} from '@/store/modules/modals/institution/modalsInstitutions.constant'

export default {
  [MODALS_INSTITUTION_GETTER_IS_LOADED_ITEM]: (state) => id => {
    return state.loadedList.find(item => item.id === id)
  },
  [MODALS_INSTITUTION_GETTER_GET_ACTIVE_ITEM]: state => {
    return state.activeItem
  },
  [MODALS_INSTITUTION_GETTER_GET_CONTACTS_PHONES]: state => {
    return state.activeItem?.contacts?.filter(item => MODAL_INSTITUTIONS_CONTACTS_PHONE_TYPE_ID_LIST.includes(item.type))
  },
  [MODALS_INSTITUTION_GETTER_GET_TITLE]: state => {
    return state.activeItem?.fullname
  },
  [MODALS_INSTITUTION_GETTER_GET_LOGO]: state => {
    return state.activeItem?.logo?.url
  },
  [MODALS_INSTITUTION_GETTER_GET_RAW_ADDRESS]: state => {
    return state.activeItem?.address?.raw
  },
  [MODALS_INSTITUTION_GETTER_GET_ABOUT]: state => {
    return state.activeItem?.about
  },
  [MODALS_INSTITUTION_GETTER_GET_ACTIVE_EMAIL]: state => {
    return state.activeItem?.contacts?.find(item => item.type === C_CONTACTS_TYPE_HOME_EMAIL)
  },
  [MODALS_INSTITUTION_GETTER_GET_URL]: state => {
    return state.activeItem?.contacts?.find(item => item.type === C_CONTACTS_TYPE_URL)?.value
  }
}
