import {
  GEO_COMPANIES_MUTATION_ADD_ITEMS,
  GEO_COMPANIES_MUTATION_ADD_REGION_LOADED
} from '@/store/modules/geo/companies/geoCompanies.constant'

export default {
  [GEO_COMPANIES_MUTATION_ADD_ITEMS] (state, values) {
    state.companiesList.push(...values)
  },
  [GEO_COMPANIES_MUTATION_ADD_REGION_LOADED] (state, value) {
    state.regionLoadedList.push(value)
  }
}
