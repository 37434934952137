import { SECTION_EVENT_TYPE } from '@/store/modules/events/events.constant'
import getters from '@/store/modules/events/getters'
import actions from '@/store/modules/events/actions'
import mutations from '@/store/modules/events/mutations'
import eventsInstitutions from '@/store/modules/events/type/eventsInstitutions'
import eventsTeacher from '@/store/modules/events/type/eventsTeacher'

export default {
  namespaced: true,
  modules: {
    [SECTION_EVENT_TYPE.EDUCATION_INSTITUTIONS]: eventsInstitutions,
    [SECTION_EVENT_TYPE.TEACHERS]: eventsTeacher
  },
  state: {
    selectedType: SECTION_EVENT_TYPE.EDUCATION_INSTITUTIONS
  },
  actions,
  mutations,
  getters
}
