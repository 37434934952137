import { debugDelay } from '@/lib/utils/env/debug'
import {
  COMMON_LOADER_GETTER_IS_LOADING,
  COMMON_LOADER_MUTATION_TOGGLE_LOADING
} from '@/store/modules/common/loader/common.loader.constant'
import {
  MODALS_INSTITUTION_ACTION_FETCH_BY_ID,
  MODALS_INSTITUTION_GETTER_IS_LOADED_ITEM,
  MODALS_INSTITUTION_MUTATION_SET_ACTIVE_ITEM,
  MODALS_INSTITUTION_MUTATION_ADD_ITEM_IN_LOADED_LIST
} from '@/store/modules/modals/institution/modalsInstitutions.constant'

export default {
  async [MODALS_INSTITUTION_ACTION_FETCH_BY_ID] ({ commit, getters }, id) {
    if (getters[COMMON_LOADER_GETTER_IS_LOADING]) {
      return false
    }
    if (getters[MODALS_INSTITUTION_GETTER_IS_LOADED_ITEM](id)) {
      commit(MODALS_INSTITUTION_MUTATION_SET_ACTIVE_ITEM, id)
      return true
    }
    const mock = require('@/mock/institution/institutionInformationById.json')
    commit(COMMON_LOADER_MUTATION_TOGGLE_LOADING, true)
    const getItem = await debugDelay(mock[id])
    commit(COMMON_LOADER_MUTATION_TOGGLE_LOADING, false)

    commit(MODALS_INSTITUTION_MUTATION_ADD_ITEM_IN_LOADED_LIST, getItem)
    commit(MODALS_INSTITUTION_MUTATION_SET_ACTIVE_ITEM, id)
  }
}
