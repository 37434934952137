import Vue from 'vue'

export const filtersPhoneMinusFormat = (phone) => {
  return phone.replace(/[^0-9]/g, '')
    .replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1-$2-$3-$4-$5')
}

export const filtersPhoneActualFormat = (phone) => {
  return phone.replace(/[^0-9]/g, '')
    .replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 ($2) $3-$4-$5')
}
/**
 * Фильтр получает на вход неотформатированый номер телефона и выводит его в формате
 * 8-999-999-99-99
 */
Vue.filter('headPhone', filtersPhoneMinusFormat)
/**
 * 8 (999) 999-99-99
 */
Vue.filter('phoneActual', filtersPhoneActualFormat)
