import { debugDelay } from '@/lib/utils/env/debug'
import { COMMON_LOADER_MUTATION_TOGGLE_LOADING } from '@/store/modules/common/loader/common.loader.constant'

import {
  REVIEWS_ACTION_FETCH,
  REVIEWS_GETTER_GET_SECTION_LOADER,
  REVIEWS_MUTATION_ADD_ITEMS_IN_SELECTED_TYPE, SECTION_REVIEWS_TYPE
} from '@/store/modules/reviews/reviews.constant'

export default {
  /**
   *
   * @param commit
   * @param getter
   * @param {{type: SECTION_REVIEWS_TYPE, page: Number}}payload
   * @return {Promise<boolean|void>}
   * @constructor
   */
  async [REVIEWS_ACTION_FETCH] ({ commit, getters }, payload) {
    if (getters[REVIEWS_GETTER_GET_SECTION_LOADER](payload.type)) {
      return false
    }
    const mockAssociate = {
      [SECTION_REVIEWS_TYPE.INSTITUTIONS_ABOUT_SERVICE]: require('@/mock/SectionReviewsInstitutionsAboutUs.json'),
      [SECTION_REVIEWS_TYPE.TEACHERS_ABOUT_SERVICE]: require('@/mock/SectionReviewsTeachersAboutUs.json'),
      [SECTION_REVIEWS_TYPE.PARENTS_ABOUT_INSTITUTIONS]: require('@/mock/SectionReviewsParentsAboutInstitutions.json')
    }
    commit(`${payload.type}/${COMMON_LOADER_MUTATION_TOGGLE_LOADING}`, true)
    const item = await debugDelay(mockAssociate[payload.type], 3500)
    commit(`${payload.type}/${COMMON_LOADER_MUTATION_TOGGLE_LOADING}`, false)
    commit(REVIEWS_MUTATION_ADD_ITEMS_IN_SELECTED_TYPE, {
      values: item,
      type: payload.type
    })
  }
}
