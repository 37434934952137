import {
  C_REQUEST_HEADER_NAME_APP_ACCESS_TOKEN,
  C_REQUEST_HEADER_NAME_BEARER_ACCESS_TOKEN, C_REQUEST_CODE_SERVICE_UNAVAILABLE
} from '@/lib/constant/c.request'
import { isProcessDevelop } from '@/lib/utils/env/process'
import {
  COMMON_REQUESTED_MUTATION_SET_ERRORS,
  COMMON_REQUESTED_MUTATION_TOGGLE_IS_REQUEST_ERROR,
  COMMON_REQUESTED_MUTATION_TOGGLE_IS_REQUEST_COMPLETE,
  COMMON_REQUESTED_MUTATION_TOGGLE_IS_REQUESTED
} from '@/store/modules/common/requested/commot.requested.constant'
import superAgent from 'superagent'

/**
 *
 * @param {Object<C_REQUEST_METHODS>} method
 * @param {string} url
 * @param {boolean} credentials
 * @param {object} headers
 */
export const factoryRequest = (method, url, credentials = false, headers = {}) => {
  const fnRequest = superAgent[method]
  if (!(fnRequest instanceof Function)) {
    throw new Error(`${method} does not exist!`)
  }

  const request = fnRequest(url)

  // todo: ввести работу с токенами
  request.set(C_REQUEST_HEADER_NAME_APP_ACCESS_TOKEN, 'templateToken')
  if (credentials) {
    request.set(C_REQUEST_HEADER_NAME_BEARER_ACCESS_TOKEN, '')
  }

  for (const name in headers) {
    request.set(name, headers[name])
  }
  return async payload => {
    try {
      let result
      if (payload) {
        result = await request.send(payload)
      } else {
        result = await request
      }
      return response(result)
    } catch (error) {
      return responseError(error)
    }
  }
}

const response = response => {
  const getResult = response?.response?.body || response?.body
  if (typeof getResult === 'undefined') {
    throw new Error('response not consist valid form value')
  }

  const { msgCode, msgText, traceId, ...result } = getResult

  const code = parseInt(msgCode)
  if (!code || (code >= 200 && code < 300)) {
    return msgText ? { msgText, ...result } : result
  } else if (code >= 400 && code < 500) {
    throw new Error(JSON.stringify({ msgText, traceId }))
  } else {
    throw new Error(`Внутренняя ошибка приложения. Выполните запрос позднее. Идентификатор ошибки ${traceId}`)
  }
}

const responseError = error => {
  if (isProcessDevelop) {
    console.error('DEVELOPMENT_MESSAGE: ', error, '\n API_URL: ', error.url)
  }
  return { msgCode: error?.status || C_REQUEST_CODE_SERVICE_UNAVAILABLE, msgText: error }
}

export const apiCompileResult = async (response, commit) => {
  try {
    commit(COMMON_REQUESTED_MUTATION_TOGGLE_IS_REQUESTED, true)
    const result = await response
    commit(COMMON_REQUESTED_MUTATION_TOGGLE_IS_REQUEST_COMPLETE, true)
    return result
  } catch (e) {
    const error = e.message || e.msgText
    commit(COMMON_REQUESTED_MUTATION_TOGGLE_IS_REQUEST_ERROR, true)
    commit(COMMON_REQUESTED_MUTATION_SET_ERRORS, [{ msgText: error }])
  }
}
