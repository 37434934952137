import commonLoader from '@/store/modules/common/loader/commonLoader?modals-institutions'
import modalsCommonGetters from '@/store/modules/modals/common/getters'
import modalsCommonMutations from '@/store/modules/modals/common/mutations'
import actions from '@/store/modules/modals/institution/actions'
import getters from '@/store/modules/modals/institution/getters'
import mutations from '@/store/modules/modals/institution/mutations'

export default {
  namespaced: true,
  modules: {
    commonLoader
  },
  state: {
    loadedList: [],
    activeItem: {},
    modalName: 'institution-item-modal',
    open: false
  },
  getters: {
    ...modalsCommonGetters,
    ...getters
  },
  mutations: {
    ...modalsCommonMutations,
    ...mutations
  },
  actions
}
