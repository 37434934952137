/**
 * Функция переворачивает значение subject если value undefined и устанавливает булевское значение value если оно
 * указано
 * @param {boolean} subject
 * @param {boolean|undefined}value
 * @return {boolean}
 */
export const toggleValues = function (subject, value = undefined) {
  if (typeof value === 'undefined') {
    return !subject
  }
  return !!value
}
