import { C_SYSTEM_RESOLUTION_TYPE } from '@/lib/constant/c.system'

const resolutionMatch = (width) => {
  const match = window.matchMedia(`(max-width: ${width})`)
  return match.matches
}

export default () => {
  for (const item in C_SYSTEM_RESOLUTION_TYPE) {
    if (item !== 'default') {
      const checkResolution = resolutionMatch(item)
      if (checkResolution) {
        return C_SYSTEM_RESOLUTION_TYPE[item]
      }
    }
  }
  return C_SYSTEM_RESOLUTION_TYPE.default
}
