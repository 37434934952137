import { toggleValues } from '@/lib/utils/storeUtils'
import { MODALS_MUTATION_TOGGLE_OPEN } from '@/store/modules/modals/modals.constant'

export default {

  /**
   * @param state
   * @param {boolean|undefined} value
   */
  [MODALS_MUTATION_TOGGLE_OPEN]: (state, value) => {
    if (state.nested <= 1) {
      state.isOpen = toggleValues(state.isOpen, value)
      if (state.isOpen === true) {
        state.nested++
      } else {
        state.nested--
      }
    } else {
      if (value === true) {
        state.nested++
      } else {
        state.nested--
      }
    }
  }

}
