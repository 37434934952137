import { C_SYSTEM_RESOLUTION_TYPE } from '@/lib/constant/c.system'
import actions from '@/store/modules/system/actions'
import systemLoading from '@/store/modules/system/loading/systemLoading'
import mutations from '@/store/modules/system/mutations'
import getters from '@/store/modules/system/getters'
import commonLoader from '@/store/modules/common/loader/commonLoader?module-system'
import systemBreadcrumb from '@/store/modules/system/breadcrumb/systemBreadcrumb'
import { STORE_NAME_SYSTEM_BREADCRUMB, STORE_NAME_SYSTEM_LOADING } from '@/store/store.list'
export default {
  namespaced: true,
  modules: {
    commonLoader,
    [STORE_NAME_SYSTEM_LOADING]: systemLoading,
    [STORE_NAME_SYSTEM_BREADCRUMB]: systemBreadcrumb
  },
  state: {
    resolutionType: C_SYSTEM_RESOLUTION_TYPE.default,
    normativeDocumentList: [],
    mainSliderBackgrounds: []
  },
  getters,
  mutations,
  actions
}
