import {
  COMMON_REQUESTED_GETTER_IS_REQUESTED,
  COMMON_REQUESTED_GETTER_IS_REQUEST_COMPLETE, COMMON_REQUESTED_GETTER_IS_REQUEST_ERROR, COMMON_REQUESTED_GETTER_ERRORS
} from '@/store/modules/common/requested/commot.requested.constant'

export default {
  [COMMON_REQUESTED_GETTER_IS_REQUESTED] (state) {
    return state.isRequested
  },
  [COMMON_REQUESTED_GETTER_IS_REQUEST_COMPLETE] (state) {
    return state.isRequestComplete
  },
  [COMMON_REQUESTED_GETTER_IS_REQUEST_ERROR] (state) {
    return state.isRequestError
  },
  [COMMON_REQUESTED_GETTER_ERRORS] (state) {
    return state.errors
  }
}
