import { DEFAULT_LIMITED_TO_DISPLAY } from '@/lib/constant/c.system'

export const SECTION_REVIEWS_TYPE = {
  INSTITUTIONS_ABOUT_SERVICE: 'institutionsAboutService',
  TEACHERS_ABOUT_SERVICE: 'teachersAboutService',
  PARENTS_ABOUT_INSTITUTIONS: 'parentsAboutInstitutions'
}

export const DEFAULT_COUNT_LIMITED_REVIEWS_TO_DISPLAY = DEFAULT_LIMITED_TO_DISPLAY

export const REVIEWS_MUTATION_SET_SELECTED_TYPE = 'reviews_mutation_set_selected_type'
export const REVIEWS_MUTATION_ADD_ITEMS_IN_SELECTED_TYPE = 'reviews_mutation_add_item_in_selected_type'

export const REVIEWS_GETTER_GET_SECTION_LOADER = 'reviews_getter_get_section_loader'
export const REVIEWS_GETTER_GET_LIST_BY_SELECTED_TYPE = 'reviews_getter_get_list_by_selected_type'
export const REVIEWS_GETTER_GET_LIMITED_LIST_BY_SELECTED_TYPE = 'reviews_getter_get_limited_list_by_selected_type'
export const REVIEWS_GETTER_GET_PAGE_BY_SELECTED_TYPE = 'reviews_getter_get_page_by_selected_type'
export const REVIEWS_GETTER_GET_SELECTED_TYPE = 'reviews_getter_get_selected_type'
export const REVIEWS_ACTION_FETCH = 'reviews_action_fetch'
