import {
  C_CONTACTS_TYPE_MOBILE_HOME,
  C_CONTACTS_TYPE_MOBILE_OFFICE,
  C_CONTACTS_TYPE_STATION_HOME, C_CONTACTS_TYPE_STATION_OFFICE
} from '@/lib/constant/c.contacts'

export const MODAL_INSTITUTIONS_CONTACTS_PHONE_TYPE_ID_LIST = [
  C_CONTACTS_TYPE_MOBILE_HOME,
  C_CONTACTS_TYPE_MOBILE_OFFICE,
  C_CONTACTS_TYPE_STATION_HOME,
  C_CONTACTS_TYPE_STATION_OFFICE]

export const MODALS_INSTITUTION_ACTION_FETCH_BY_ID = 'modals_institution_action_fetch_by_id'

export const MODALS_INSTITUTION_MUTATION_ADD_ITEM_IN_LOADED_LIST = 'modals_institution_mutation_add_item_in_loaded_list'
export const MODALS_INSTITUTION_MUTATION_SET_ACTIVE_ITEM = 'modals_institution_mutation_set_active_item'
export const MODALS_INSTITUTION_MUTATION_CLEAR_ACTIVE_ITEM = 'modals_institution_mutation_clear_active_item'

export const MODALS_INSTITUTION_GETTER_IS_LOADED_ITEM = 'modals_institution_getter_is_loaded_item'
export const MODALS_INSTITUTION_GETTER_GET_ACTIVE_ITEM = 'modals_institution_getter_get_active_item'
export const MODALS_INSTITUTION_GETTER_GET_CONTACTS_PHONES = 'modals_institution_getter_get_contacts_phones'
export const MODALS_INSTITUTION_GETTER_GET_TITLE = 'modals_institution_getter_get_title'
export const MODALS_INSTITUTION_GETTER_GET_LOGO = 'modals_institution_getter_get_logo'
export const MODALS_INSTITUTION_GETTER_GET_RAW_ADDRESS = 'modals_institution_getter_get_raw_address'
export const MODALS_INSTITUTION_GETTER_GET_ABOUT = 'modals_institution_getter_get_about'
export const MODALS_INSTITUTION_GETTER_GET_ACTIVE_EMAIL = 'modals_institution_getter_get_active_email'
export const MODALS_INSTITUTION_GETTER_GET_URL = 'modals_institution_getter_get_url'
