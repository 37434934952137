// import BreadcrumpTemplate from '@/components/misc/BreadcrumpTemplate'
import $Storage from '~/lib/utils/$Storage'
import { getOneDay } from '~/common/utils/dateTime'
import { STORAGE_VISIT_DATA_PATH } from '~/common/constants/storage'
import { setActiveValidator, ValidationProvider, ValidationObserver } from '@/lib/form/formValidator'
import windowResolution from '@/lib/windowResolution'
import { SYSTEM_MUTATION_SET_RESOLUTION_TYPE } from '@/store/modules/system/system.constant'
import { STORE_PATH_SYSTEM } from '@/store/store.list'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import getI18N from './lib/locale/locales'
import VueMeta from 'vue-meta'
// Необходимо выключить unuse так как эта переменная по сути устанавливает дефолтное значение для console.log
// eslint-disable-next-line no-unused-vars
import { debugLog } from './lib/utils/env/debug'
import router from './router'
import store from './store'
import '@/lib/filters'
import { Plugin as VueFragment } from 'vue-fragment'
import VModal from 'vue-js-modal'
// import 'vue-js-modal/dist/styles.css'

import { apiGetVisit } from '~/api/visit'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
Vue.use(VModal, {
  draggable: true,
  dynamicDefault: {
    opened: () => {
      console.log('t')
      return true
    },
    'before-close': event => { console.log(event) },
    beforeClose: event => { console.log(event) },
    beforeClosed: event => { console.log('closed') }
  }
})
Vue.use(VueI18n)
Vue.use(VueFragment)

async function xCore24Visit () {
  if (!$Storage.get(STORAGE_VISIT_DATA_PATH)) {
    const data = await apiGetVisit()
    $Storage.set(STORAGE_VISIT_DATA_PATH, data, getOneDay())
  }
}

async function main () {
  const i18n = await getI18N()

  // устанавливаем настройки i18n для валидатора
  setActiveValidator(i18n)

  // Посетим Платформу за настройками для Портала
  await xCore24Visit()

  store.commit(`${STORE_PATH_SYSTEM}/${SYSTEM_MUTATION_SET_RESOLUTION_TYPE}`, windowResolution())
  new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}
main().then()
