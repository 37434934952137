import { debugDelay } from '@/lib/utils/env/debug'
import {
  COMMON_LOADER_GETTER_IS_LOADING,
  COMMON_LOADER_MUTATION_TOGGLE_LOADING
} from '@/store/modules/common/loader/common.loader.constant'
import { PROMO_ACTION_FETCH, PROMO_MUTATION_ADD_ITEMS } from '@/store/modules/promo/promo.constant'

export default {
  async [PROMO_ACTION_FETCH] ({ commit, getters }, payload) {
    if (getters[COMMON_LOADER_GETTER_IS_LOADING]) {
      return false
    }

    const mock = require('@/mock/SectionPromo.json')
    commit(COMMON_LOADER_MUTATION_TOGGLE_LOADING, true)
    const item = await debugDelay(mock)
    commit(COMMON_LOADER_MUTATION_TOGGLE_LOADING, false)
    commit(PROMO_MUTATION_ADD_ITEMS, item)
  }
}
