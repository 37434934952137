import {
  SYSTEM_MUTATION_SET_RESOLUTION_TYPE,
  SYSTEM_MUTATION_SET_NORMATIVE_DOCUMENTS
} from '@/store/modules/system/system.constant'

export default {
  /**
   *
   * @param state
   * @param {C_SYSTEM_RESOLUTION_TYPE} value
   */
  [SYSTEM_MUTATION_SET_RESOLUTION_TYPE]: (state, value) => {
    state.resolutionType = value
  },
  [SYSTEM_MUTATION_SET_NORMATIVE_DOCUMENTS]: (state, values) => {
    state.normativeDocumentList = values
  }
}
