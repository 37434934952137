import { ValidationProvider as Provider, ValidationObserver as Observer, extend } from 'vee-validate'
import { email, required } from 'vee-validate/dist/rules'

export const ValidationProvider = Provider
export const ValidationObserver = Observer
export const setActiveValidator = (i18n) => {
  extend('email', {
    ...email,
    message: (_, values) => i18n.t('PORTAL.VALIDATION.MESSAGE.EMAIL', values)
  })
  extend('required', {
    ...required,
    message: (_, values) => i18n.t('PORTAL.VALIDATION.MESSAGE.REQUIRED', values)
  })
}
