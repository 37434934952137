import {
  INSTITUTIONS_GETTER_GET_LIMITED_LIST,
  DEFAULT_COUNT_LIMITED_INSTITUTIONS_TO_DISPLAY, INSTITUTIONS_GETTER_GET_LIST, INSTITUTIONS_GETTER_GET_PAGE
} from '@/store/modules/institutions/institutions.constant'

export default {
  [INSTITUTIONS_GETTER_GET_LIMITED_LIST]: (state) => {
    return state.item.values.slice(0, DEFAULT_COUNT_LIMITED_INSTITUTIONS_TO_DISPLAY)
  },
  [INSTITUTIONS_GETTER_GET_LIST]: (state) => {
    return state.item.values
  },
  [INSTITUTIONS_GETTER_GET_PAGE]: (state) => {
    return state.item.page
  }
}
