import { shuffle } from 'lodash'
import { apiGetFiles } from '~/api/fs'
import $Storage from '@/lib/utils/$Storage'
import { getTenDay } from '@/lib/utils/utilsDateTime'
import { apiGetAlbumNames, apiGetAlbums } from '~/api/ui'

import {
  BACKGROUNDS_MAIN_ACTION_FETCH_MAIN_SLIDE_BG,
  BACKGROUNDS_MAIN_MUTATION_SET_MAIN_SLIDE_BG
} from '@/store/modules/backgrounds/main/backgroundsMain.constant'

import {
  COMMON_LOADER_GETTER_IS_LOADING,
  COMMON_LOADER_MUTATION_TOGGLE_LOADING
} from '@/store/modules/common/loader/common.loader.constant'

const BACKGROUND_BASE_NAMESPACE = 'ke-portal-slider'

const getBackgrounds = async () => {
  const namespaces = await apiGetAlbumNames(BACKGROUND_BASE_NAMESPACE)
  const nss = shuffle(namespaces)
  const { id, name } = nss[0]
  const albums = await apiGetAlbums(id)
  console.log('Загружаем фоны для слайдера из name', name)
  const { fileIds } = albums[0]
  return (Array.isArray(fileIds) && fileIds.length ? apiGetFiles(fileIds, 'url') : [])
}

export default {
  async [BACKGROUNDS_MAIN_ACTION_FETCH_MAIN_SLIDE_BG] ({ commit, getters }) {
    if (getters[COMMON_LOADER_GETTER_IS_LOADING]) {
      return false
    }

    const cachedBackgrounds = $Storage.get(BACKGROUNDS_MAIN_MUTATION_SET_MAIN_SLIDE_BG)
    if (cachedBackgrounds) {
      commit(BACKGROUNDS_MAIN_MUTATION_SET_MAIN_SLIDE_BG, cachedBackgrounds)
      return true
    }

    commit(COMMON_LOADER_MUTATION_TOGGLE_LOADING, true)
    const data = await getBackgrounds()

    commit(COMMON_LOADER_MUTATION_TOGGLE_LOADING, false)
    if (data.length > 0) {
      $Storage.set(BACKGROUNDS_MAIN_MUTATION_SET_MAIN_SLIDE_BG, data, getTenDay())
    }
    commit(BACKGROUNDS_MAIN_MUTATION_SET_MAIN_SLIDE_BG, data)
  }
}
