import {
  ARTICLES_RECOMMENDATION_GETTER_GET_LIMITED_LIST,
  ARTICLES_RECOMMENDATION_DEFAULT_COUNT_FILTERED_VALUE,
  ARTICLES_RECOMMENDATION_GETTER_GET_LIST,
  ARTICLES_RECOMMENDATION_GETTER_GET_PAGE
} from '@/store/modules/articles/recommendation/articlesRecommendation.constant'

export default {
  [ARTICLES_RECOMMENDATION_GETTER_GET_LIMITED_LIST] (state) {
    return state.item.values.slice(0, ARTICLES_RECOMMENDATION_DEFAULT_COUNT_FILTERED_VALUE)
  },
  [ARTICLES_RECOMMENDATION_GETTER_GET_LIST] (state) {
    return state.item.values
  },
  [ARTICLES_RECOMMENDATION_GETTER_GET_PAGE] (state) {
    return state.item.page
  }
}
