import { apiCompileResult } from '@/lib/api/apiUtils'
import { debugDelay } from '@/lib/utils/env/debug'
import {
  ARTICLES_RECOMMENDATION_ACTION_FETCH,
  ARTICLES_RECOMMENDATION_MUTATION_CLEAR_ITEMS, ARTICLES_RECOMMENDATION_MUTATION_ADD_ITEMS
} from '@/store/modules/articles/recommendation/articlesRecommendation.constant'
import { COMMON_LOADER_MUTATION_TOGGLE_LOADING } from '@/store/modules/common/loader/common.loader.constant'
import { COMMON_REQUESTED_GETTER_IS_REQUEST_ERROR } from '@/store/modules/common/requested/commot.requested.constant'

export default {
  /**
   *
   * @param commit
   * @param getters
   * @param {{type: String}}payload
   */
  async [ARTICLES_RECOMMENDATION_ACTION_FETCH] ({ commit, getters }, payload) {
    commit(COMMON_LOADER_MUTATION_TOGGLE_LOADING, true)
    commit(ARTICLES_RECOMMENDATION_MUTATION_CLEAR_ITEMS)
    const mock = require('@/mock/articles/recommendation/ArticlesRecommendation.json')
    const request = debugDelay(mock, 4000)
    const response = await apiCompileResult(request, commit)
    commit(COMMON_LOADER_MUTATION_TOGGLE_LOADING, false)
    if (getters[COMMON_REQUESTED_GETTER_IS_REQUEST_ERROR]) {
      console.log('Не удалось получить список рекомендованных статей')
      return false
    }
    commit(ARTICLES_RECOMMENDATION_MUTATION_ADD_ITEMS, response)
    return true
  }
}
