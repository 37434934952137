import { KE } from '~/common/constants/services'
import { factoryRequestSender } from '~/common/utils'

export const apiGetResources = async (offset = null) => {
  const query = { offset }
  const send = factoryRequestSender(KE, 'getResources', { query })
  const { result } = await send()
  return result
}

export const apiGetDocuments = async (offset = null) => {
  const query = { offset }
  const send = factoryRequestSender(KE, 'getDocuments', { query })
  const { result } = await send()
  return result
}

export const apiGetEduInstitutions = async () => {
  const send = factoryRequestSender(KE, 'getEduInstitutions')
  const { result } = await send()
  return result
}
