import { COMMON_LOADER_GETTER_IS_LOADING } from '@/store/modules/common/loader/common.loader.constant'
import {
  DOCUMENTS_GETTER_GET_SECTION_LOADER,
  DOCUMENTS_GETTER_GET_LIST_BY_SELECTED_TYPE,
  DOCUMENTS_GETTER_GET_LIMITED_LIST_BY_SELECTED_TYPE,
  DOCUMENTS_GETTER_GET_PAGE_BY_SELECTED_TYPE, DEFAULT_COUNT_LIMITED_DOCUMENTS_TO_DISPLAY
} from '@/store/modules/documents/documents.constant'

export default {
  [DOCUMENTS_GETTER_GET_SECTION_LOADER]: (state, getter) => (type) => {
    return getter[`${type}/${COMMON_LOADER_GETTER_IS_LOADING}`]
  },
  [DOCUMENTS_GETTER_GET_LIST_BY_SELECTED_TYPE]: (state) => {
    return state[state.selectedType].item.values
  },
  [DOCUMENTS_GETTER_GET_LIMITED_LIST_BY_SELECTED_TYPE]: (state) => {
    return state[state.selectedType].item.values.slice(0, DEFAULT_COUNT_LIMITED_DOCUMENTS_TO_DISPLAY)
  },
  [DOCUMENTS_GETTER_GET_PAGE_BY_SELECTED_TYPE]: (state) => {
    return state[state.selectedType].item.page
  }
}
