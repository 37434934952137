import { COMMON_LOADER_GETTER_IS_LOADING } from '@/store/modules/common/loader/common.loader.constant'
import {
  REVIEWS_GETTER_GET_SECTION_LOADER,
  REVIEWS_GETTER_GET_LIST_BY_SELECTED_TYPE,
  REVIEWS_GETTER_GET_LIMITED_LIST_BY_SELECTED_TYPE,
  REVIEWS_GETTER_GET_PAGE_BY_SELECTED_TYPE, DEFAULT_COUNT_LIMITED_REVIEWS_TO_DISPLAY, REVIEWS_GETTER_GET_SELECTED_TYPE
} from '@/store/modules/reviews/reviews.constant'

export default {
  [REVIEWS_GETTER_GET_SECTION_LOADER]: (state, getter) => (type) => {
    return getter[`${type}/${COMMON_LOADER_GETTER_IS_LOADING}`]
  },
  [REVIEWS_GETTER_GET_SELECTED_TYPE]: (state) => {
    return state.selectedType
  },
  [REVIEWS_GETTER_GET_LIST_BY_SELECTED_TYPE]: (state) => {
    return state[state.selectedType].item.values
  },
  [REVIEWS_GETTER_GET_LIMITED_LIST_BY_SELECTED_TYPE]: (state) => {
    const value = state[state.selectedType].item.values
    if (typeof value === 'undefined') {
      return []
    }
    return value.slice(0, DEFAULT_COUNT_LIMITED_REVIEWS_TO_DISPLAY)
  },
  [REVIEWS_GETTER_GET_PAGE_BY_SELECTED_TYPE]: (state) => {
    return state[state.selectedType].item.page
  }
}
