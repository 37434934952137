export const COMMON_REQUESTED_MUTATION_TOGGLE_IS_REQUESTED = 'common_requested_mutation_toggle_is_requested'
export const COMMON_REQUESTED_MUTATION_TOGGLE_IS_REQUEST_COMPLETE = 'common_requested_mutation_toggle_is_request_complete'
export const COMMON_REQUESTED_MUTATION_TOGGLE_IS_REQUEST_ERROR = 'common_requested_mutation_toggle_is_request_error'
export const COMMON_REQUESTED_MUTATION_SET_ERRORS = 'common_requested_mutation_set_errors'
export const COMMON_REQUESTED_MUTATION_CLEAR = 'common_requested_mutation_clear'

export const COMMON_REQUESTED_GETTER_IS_REQUESTED = 'common_requested_getter_is_requested'
export const COMMON_REQUESTED_GETTER_IS_REQUEST_COMPLETE = 'common_requested_getter_is_request_complete'
export const COMMON_REQUESTED_GETTER_IS_REQUEST_ERROR = 'common_requested_getter_is_request_error'
export const COMMON_REQUESTED_GETTER_ERRORS = 'common_requested_getter_errors'
