export const ARTICLES_RECOMMENDATION_DEFAULT_COUNT_FILTERED_VALUE = 4

export const ARTICLES_RECOMMENDATION_ACTION_FETCH = 'articles_recommendation_action_fetch'

export const ARTICLES_RECOMMENDATION_MUTATION_ADD_ITEMS = 'articles_recommendation_mutation_add_items'
export const ARTICLES_RECOMMENDATION_MUTATION_CLEAR_ITEMS = 'articles_recommendation_mutation_clear_items'

export const ARTICLES_RECOMMENDATION_GETTER_GET_LIMITED_LIST = 'articles_recommendation_getter_get_limited_list'
export const ARTICLES_RECOMMENDATION_GETTER_GET_LIST = 'articles_recommendation_getter_get_list'
export const ARTICLES_RECOMMENDATION_GETTER_GET_PAGE = 'articles_recommendation_getter_get_page'
