/**
 *
 * @type {{DELETE: string, POST: string, GET: string, UPDATE: string}}
 */
export const C_REQUEST_METHODS = {
  POST: 'post',
  GET: 'get',
  UPDATE: 'update',
  DELETE: 'delete'
}

export const C_REQUEST_HEADER_NAME_APP_ACCESS_TOKEN = 'X-Application-Access-Token'
export const C_REQUEST_HEADER_NAME_SECRET_ACCESS_TOKEN = 'X-Secret-Access-Token'
export const C_REQUEST_HEADER_NAME_BEARER_ACCESS_TOKEN = 'Authorization'

export const C_REQUEST_CODE_SERVICE_UNAVAILABLE = 503
