import {
  PROMO_GETTER_GET_LIMITED_LIST,
  DEFAULT_COUNT_LIMITED_PROMOS_TO_DISPLAY, PROMO_GETTER_GET_LIST, PROMO_GETTER_GET_PAGE
} from '@/store/modules/promo/promo.constant'

export default {
  [PROMO_GETTER_GET_LIMITED_LIST]: (state) => {
    return state.item.values.slice(0, DEFAULT_COUNT_LIMITED_PROMOS_TO_DISPLAY)
  },
  [PROMO_GETTER_GET_LIST]: (state) => {
    return state.item.values
  },
  [PROMO_GETTER_GET_PAGE]: (state) => {
    return state.item.page
  }
}
