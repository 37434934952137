import { COMMON_LOADER_GETTER_IS_LOADING } from '@/store/modules/common/loader/common.loader.constant'
import {
  DEFAULT_COUNT_LIMITED_EVENTS_TO_DISPLAY,
  EVENTS_GETTER_GET_LIST_BY_SELECTED_TYPE,
  EVENTS_GETTER_GET_LIMITED_LIST_BY_SELECTED_TYPE,
  EVENTS_GETTER_GET_PAGE_BY_SELECTED_TYPE, EVENTS_GETTER_GET_SECTION_LOADER
} from '@/store/modules/events/events.constant'

export default {
  [EVENTS_GETTER_GET_SECTION_LOADER]: (state, getter) => (type) => {
    return getter[`${type}/${COMMON_LOADER_GETTER_IS_LOADING}`]
  },
  [EVENTS_GETTER_GET_LIST_BY_SELECTED_TYPE]: (state) => {
    return state[state.selectedType].item.values
  },
  [EVENTS_GETTER_GET_LIMITED_LIST_BY_SELECTED_TYPE]: (state) => {
    return state[state.selectedType].item.values.slice(0, DEFAULT_COUNT_LIMITED_EVENTS_TO_DISPLAY)
  },
  [EVENTS_GETTER_GET_PAGE_BY_SELECTED_TYPE]: (state) => {
    return state[state.selectedType].item.page
  }
}
