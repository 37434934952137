export const FORMS_ORDER_GETTER_GET_PERSON = 'forms_order_getter_get_person'
export const FORMS_ORDER_GETTER_GET_MAIL = 'form_order_getters_get_mail'
export const FORMS_ORDER_GETTER_GET_PHONE = 'forms_order_getter_get_phone'
export const FORMS_ORDER_GETTER_GET_AGREEMENT = 'forms_order_getter_get_agreement'
export const FORMS_ORDER_GETTER_GET_FETCH = 'forms_order_getter_get_fetch'

export const FORMS_ORDER_MUTATION_SET_PERSON = 'forms_order_mutation_set_person'
export const FORMS_ORDER_MUTATION_SET_MAIL = 'forms_order_mutation_set_mail'
export const FORMS_ORDER_MUTATION_SET_PHONE = 'forms_order_mutation_set_phone'
export const FORMS_ORDER_MUTATION_SET_AGREEMENT = 'forms_order_mutation_set_agreement'
export const FORMS_ORDER_MUTATION_CLEAR_DATA = 'forms_order_mutation_clear_data'

export const FORMS_ORDER_ACTION_SEND_DATA = 'forms_order_action_send_data'
