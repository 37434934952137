import commonLoader from '@/store/modules/common/loader/commonLoader?modules-geo-companies'
import actions from '@/store/modules/geo/companies/actions'
import getters from '@/store/modules/geo/companies/getters'
import mutations from '@/store/modules/geo/companies/mutations'

export default {
  namespaced: true,
  modules: {
    commonLoader
  },
  state: {
    companiesList: [],
    regionLoadedList: []
  },
  actions,
  getters,
  mutations
}
