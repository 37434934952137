import actions from '@/store/modules/reviews/actions'
import getters from '@/store/modules/reviews/getters'
import mutations from '@/store/modules/reviews/mutations'
import { SECTION_REVIEWS_TYPE } from '@/store/modules/reviews/reviews.constant'
import reviewsTypeInstitutionsAboutService from '@/store/modules/reviews/type/reviewsTypeInstitutionsAboutService'
import reviewsTypeParentsAboutInstitutions from '@/store/modules/reviews/type/reviewsTypeParentsAboutInstitutions'
import reviewsTypeTeachersAboutService from '@/store/modules/reviews/type/reviewsTypeTeachersAboutService'

export default {
  namespaced: true,
  modules: {
    [SECTION_REVIEWS_TYPE.INSTITUTIONS_ABOUT_SERVICE]: reviewsTypeInstitutionsAboutService,
    [SECTION_REVIEWS_TYPE.TEACHERS_ABOUT_SERVICE]: reviewsTypeTeachersAboutService,
    [SECTION_REVIEWS_TYPE.PARENTS_ABOUT_INSTITUTIONS]: reviewsTypeParentsAboutInstitutions
  },
  state: {
    selectedType: SECTION_REVIEWS_TYPE.INSTITUTIONS_ABOUT_SERVICE
  },
  actions,
  getters,
  mutations
}
