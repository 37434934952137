import { get } from 'lodash'
import { stringify } from 'querystring'
import { METHODS } from '../constants/http'
import $Storage from '~/lib/utils/$Storage'
import { STORAGE_VISIT_DATA_PATH } from '../constants/storage'

const URL_NOT_DEFINED_ERROR = 'URL_NOT_DEFINED'
const SERVICE_NOT_FOUND_ERROR = 'SERVICE_NOT_FOUND'
const INVALID_HTTP_METHOD_ERROR = 'INVALID_HTTP_METHOD'
const ENDPOINTS_NOT_DEFINED_ERROR = 'ENDPOINTS_NOT_DEFINED'
const ENDPOINT_PATH_NOT_DEFINED_ERROR = 'ENDPOINT_PATH_NOT_DEFINED'
const ENDPOINT_METHOD_NOT_DEFINED_ERROR = 'ENDPOINT_METHOD_NOT_DEFINED'

const factoryOptions = () => {
  // const visitData = $Storage.get(STORAGE_VISIT_DATA_PATH)
  // console.log(visitData)
  return (service, endpointPath, options = {}) => {
    const visitData = $Storage.get(STORAGE_VISIT_DATA_PATH)
    // console.log(visitData)
    const { [service]: endpoint } = visitData.services
    if (typeof endpoint === 'undefined') {
      console.error(`В настройках visit не найден сервис "${service}"`)
      throw new Error(SERVICE_NOT_FOUND_ERROR)
    }

    const { endpoints, url } = endpoint
    if (typeof endpoints === 'undefined') {
      console.error(`В настройках visit не определены endpoints для сервиса "${service}"`)
      throw new Error(ENDPOINTS_NOT_DEFINED_ERROR)
    }

    if (typeof url === 'undefined') {
      console.error(`В настройках visit не определен url для сервиса "${service}"`)
      throw new Error(URL_NOT_DEFINED_ERROR)
    }

    const { method, path, query: defaultQuery = '' } = get(endpoints, endpointPath)
    if (!method || typeof method === 'undefined') {
      console.error(`В настройках visit не определен method для сервиса "${service}" по пути "${endpointPath}"`)
      throw new Error(ENDPOINT_METHOD_NOT_DEFINED_ERROR)
    }

    if (!path || typeof path === 'undefined') {
      console.error(`В настройках visit не определен path для сервиса "${service}" по пути "${endpointPath}"`)
      throw new Error(ENDPOINT_PATH_NOT_DEFINED_ERROR)
    }
    // console.log(METHODS)
    // console.log(Object.values(METHODS))
    // console.log(method)
    // console.log()
    if (!(Object.values(METHODS).includes(method) || Object.values(METHODS).includes(method.toLowerCase()))) {
      console.error(`В настройках visit для сервиса "${service}" для endpoint "${endpointPath}" определен неверный method "${method}"`)
      throw new Error(INVALID_HTTP_METHOD_ERROR)
    }

    const parts = [url]

    const { query, ...params } = options

    if (typeof params !== 'undefined') {
      parts.push(fillParams(path, params))
    } else {
      parts.push(path)
    }

    // QUERY
    const queryParts = []
    if (defaultQuery) {
      queryParts.push('?')
      queryParts.push(defaultQuery)
    }

    if (typeof query !== 'undefined') {
      if (!queryParts.length) {
        queryParts.push('?')
      }
      queryParts.push(stringify(query))
    }

    if (queryParts.length) {
      parts.push(queryParts.join('&'))
    }

    return {
      method: method.toLowerCase(),
      url: parts.join('')
    }
  }
}

const fillParams = (path, params) => {
  for (const name of Object.keys(params)) {
    path = path.replace(`:${name}`, `${params[name]}`)
  }
  return path
}

const optionsFor = factoryOptions()

export const getEndpointOptions = (service, path, options = {}) => optionsFor(service, path, options)
