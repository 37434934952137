import mutations from '@/store/modules/common/loader/mutations'
import getters from '@/store/modules/common/loader/getters'

export default {
  state: {
    isLoading: false
  },
  mutations,
  getters
}
