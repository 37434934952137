import getters from '@/store/modules/system/loading/getters'
import mutations from '@/store/modules/system/loading/mutations'

export default {
  namespaced: true,
  state: {
    isLoading: false
  },
  mutations,
  getters
}
