export const METHODS = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
}

export const HEADER_NAMES = {
  X_APPLICATION_DEVEL: 'X-Application-Devel',
  X_APPLICATION_ACCESS_TOKEN: 'X-Application-Access-Token'
}
