<template>
    <div class="loader" :class="{
      'loader--relative': isRelative,
    'loader--global': isGlobal
    }">
        <i class="icon-portal icon-x-loading icon-portal--rotated "></i>
    </div>
</template>

<script>
export default {
  name: 'BaseLoader',
  props: {
    isGlobal: {
      type: Boolean,
      default: false
    },
    isRelative: {
      type: Boolean,
      default: false
    }
  }
}
</script>
