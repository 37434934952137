import {
  GEO_COMPANIES_GETTER_GET_LIST,
  GEO_COMPANIES_GETTER_GET_REGIONS_LOADED_LIST, GEO_COMPANIES_GETTER_IS_REGION_LOADED
} from '@/store/modules/geo/companies/geoCompanies.constant'

export default {
  [GEO_COMPANIES_GETTER_GET_LIST] (state) {
    return state.companiesList
  },
  [GEO_COMPANIES_GETTER_GET_REGIONS_LOADED_LIST]: (state) => {
    return state.regionLoadedList
  },
  [GEO_COMPANIES_GETTER_IS_REGION_LOADED]: (state) => id => {
    return state.regionLoadedList.includes(id)
  }
}
