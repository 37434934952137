import { toggleValues } from '@/lib/utils/storeUtils'
import {
  COMMON_REQUESTED_MUTATION_CLEAR,
  COMMON_REQUESTED_MUTATION_SET_ERRORS,
  COMMON_REQUESTED_MUTATION_TOGGLE_IS_REQUEST_COMPLETE,
  COMMON_REQUESTED_MUTATION_TOGGLE_IS_REQUEST_ERROR, COMMON_REQUESTED_MUTATION_TOGGLE_IS_REQUESTED
} from '@/store/modules/common/requested/commot.requested.constant'

export default {
  [COMMON_REQUESTED_MUTATION_CLEAR] (state) {
    state.isRequested = false
    state.isRequestComplete = false
    state.isRequestError = false
    state.errors = []
  },
  /**
   *
   * @param state
   * @param {Array} errors
   */
  [COMMON_REQUESTED_MUTATION_SET_ERRORS] (state, errors) {
    state.errors = errors
  },
  [COMMON_REQUESTED_MUTATION_TOGGLE_IS_REQUEST_COMPLETE] (state, value = undefined) {
    state.isRequestComplete = toggleValues(state.isRequestComplete, value)
  },
  [COMMON_REQUESTED_MUTATION_TOGGLE_IS_REQUEST_ERROR] (state, value) {
    state.isRequestError = toggleValues(state.isRequestError, value)
  },
  [COMMON_REQUESTED_MUTATION_TOGGLE_IS_REQUESTED] (state, value) {
    state.isRequested = toggleValues(state.isRequested, value)
  }
}
