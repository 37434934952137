import { UI } from '~/common/constants/services'
import { factoryRequestSender } from '~/common/utils'

export const apiGetAlbumNames = async (alias) => {
  const send = factoryRequestSender(UI, 'getAlbumNames', { alias })
  const { result: albumNamesData } = await send()
  return albumNamesData.filter(({ parentId }) => parentId > 0)
}

export const apiGetAlbums = async (albumId) => {
  const send = factoryRequestSender(UI, 'getAlbums', { id: albumId })
  const { result } = await send()
  return result
}
