import {
  FORMS_ORDER_GETTER_GET_AGREEMENT,
  FORMS_ORDER_GETTER_GET_MAIL, FORMS_ORDER_GETTER_GET_PHONE, FORMS_ORDER_GETTER_GET_PERSON, FORMS_ORDER_GETTER_GET_FETCH
} from '@/store/modules/forms/order/formsOrder.constant'

export default {
  [FORMS_ORDER_GETTER_GET_AGREEMENT] (state) {
    return state.agreement
  },
  [FORMS_ORDER_GETTER_GET_MAIL] (state) {
    return state.email
  },
  [FORMS_ORDER_GETTER_GET_PHONE] (state) {
    return state.phone
  },
  [FORMS_ORDER_GETTER_GET_PERSON] (state) {
    return state.person
  },
  [FORMS_ORDER_GETTER_GET_FETCH] (state) {
    return {
      person: state.person,
      phone: state.phone,
      email: state.email,
      agreement: state.agreement
    }
  }
}
