import { C_LOCAL_STORAGE_GEO_USER } from '@/lib/constant/c.localStorage'
import $Storage from '@/lib/utils/$Storage'
import { GEO_MUTATION_SET_GEO_DEFAULT, GEO_MUTATION_SET_GEO_STORED } from '@/store/modules/geo/geo.constant'

export default {
  /**
   *
   * @param store
   * @param {{data: {city,city_with_type,country_iso_code, geo_lat, geo_lon},value}} payload
   */
  [GEO_MUTATION_SET_GEO_DEFAULT] (store, payload) {
    store.city = payload.data?.city
    store.cityWithType = payload.data?.city_with_type
    store.isoCode = payload.data?.country_iso_code
    store.lat = payload.data?.geo_lat
    store.lon = payload.data?.geo_lon

    $Storage.set(C_LOCAL_STORAGE_GEO_USER, {
      city: store.city,
      cityWithType: store.cityWithType,
      isoCode: store.isoCode,
      lat: store.lat,
      lon: store.lon
    })
  },
  /**
   *
   * @param store
   * @param {{city, cityWithType, isoCode, lat, lon}}payload
   * @constructor
   */
  [GEO_MUTATION_SET_GEO_STORED] (store, payload) {
    if (typeof payload !== 'undefined') {
      for (const item in payload) {
        store[item] = payload[item] + ''
      }
    }
  }
}
