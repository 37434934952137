import { GEO_GETTER_GET_COORDS, GEO_GETTER_GET_CITY } from '@/store/modules/geo/geo.constant'

export default {
  [GEO_GETTER_GET_COORDS] (store) {
    return [store.lat, store.lon]
  },
  [GEO_GETTER_GET_CITY] (store) {
    return store.city
  }
}
