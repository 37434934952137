import { debugDelay } from '@/lib/utils/env/debug'
import {
  COMMON_LOADER_GETTER_IS_LOADING,
  COMMON_LOADER_MUTATION_TOGGLE_LOADING
} from '@/store/modules/common/loader/common.loader.constant'
import {
  SYSTEM_ACTION_FETCH_NORMATIVE_DOCUMENTS,
  SYSTEM_MUTATION_SET_NORMATIVE_DOCUMENTS, SYSTEM_GETTER_GET_NORMATIVE_DOCUMENTS_LIST
} from '@/store/modules/system/system.constant'

export default {
  async [SYSTEM_ACTION_FETCH_NORMATIVE_DOCUMENTS] ({ commit, getters }) {
    if (getters[COMMON_LOADER_GETTER_IS_LOADING] || getters[SYSTEM_GETTER_GET_NORMATIVE_DOCUMENTS_LIST].length > 0) {
      return false
    }
    const mockData = require('@/mock/SystemNormativeDocumentsList.json')
    commit(COMMON_LOADER_MUTATION_TOGGLE_LOADING, true)
    const item = await debugDelay(mockData, 3000)
    commit(COMMON_LOADER_MUTATION_TOGGLE_LOADING, false)

    commit(SYSTEM_MUTATION_SET_NORMATIVE_DOCUMENTS, item)
  }

}
