import {
  ARTICLES_RECOMMENDATION_MUTATION_ADD_ITEMS,
  ARTICLES_RECOMMENDATION_MUTATION_CLEAR_ITEMS
} from '@/store/modules/articles/recommendation/articlesRecommendation.constant'

export default {
  [ARTICLES_RECOMMENDATION_MUTATION_ADD_ITEMS] (state, value) {
    state.item.values.push(...value)
    state.item.page++
  },
  [ARTICLES_RECOMMENDATION_MUTATION_CLEAR_ITEMS] (state) {
    state.item.values = []
    state.item.page = 0
  }
}
