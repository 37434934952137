import getters from '@/store/modules/system/breadcrumb/getters'
import mutations from '@/store/modules/system/breadcrumb/mutations'

export default {
  namespaced: true,
  state: {
    list: []
  },
  mutations,
  getters
}
