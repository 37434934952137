import { apiCompileResult } from '@/lib/api/apiUtils'
import { debugDelay } from '@/lib/utils/env/debug'
import {
  COMMON_REQUESTED_GETTER_IS_REQUEST_ERROR,
  COMMON_REQUESTED_MUTATION_CLEAR
} from '@/store/modules/common/requested/commot.requested.constant'
import {
  FORMS_ORDER_ACTION_SEND_DATA,
  FORMS_ORDER_GETTER_GET_FETCH, FORMS_ORDER_MUTATION_CLEAR_DATA
} from '@/store/modules/forms/order/formsOrder.constant'
import { SYSTEM_LOADING_MUTATION_TOGGLE_LOADING } from '@/store/modules/system/loading/systemLoading.constant'
import { STORE_PATH_SYSTEM_LOADING } from '@/store/store.list'

export default {
  async [FORMS_ORDER_ACTION_SEND_DATA] ({ commit, getters, rootGetters }) {
    commit(`${STORE_PATH_SYSTEM_LOADING}/${SYSTEM_LOADING_MUTATION_TOGGLE_LOADING}`, true, { root: true })
    commit(COMMON_REQUESTED_MUTATION_CLEAR)
    const sendData = getters[FORMS_ORDER_GETTER_GET_FETCH]
    const rnd = Math.fround(Math.random() * 10)
    let request
    if (rnd > 5) {
      request = debugDelay(() => {
        throw Error('тестовая ошибка')
      }, 1000)
    } else {
      request = debugDelay(sendData, 1000)
    }

    const response = await apiCompileResult(request, commit)
    console.log('sendDataOrder', response)
    // todo: по хорошему нужно еще обрабатывать серверные ошибки.
    if (getters[COMMON_REQUESTED_GETTER_IS_REQUEST_ERROR]) {
      commit(`${STORE_PATH_SYSTEM_LOADING}/${SYSTEM_LOADING_MUTATION_TOGGLE_LOADING}`, false, { root: true })
      return false
    }
    commit(FORMS_ORDER_MUTATION_CLEAR_DATA)
    commit(`${STORE_PATH_SYSTEM_LOADING}/${SYSTEM_LOADING_MUTATION_TOGGLE_LOADING}`, false, { root: true })
  }
}
