import articlesPopular from '@/store/modules/articles/popular/articlesPopular'
import articlesRecommendation from '@/store/modules/articles/recommendation/articlesRecommendation'
import { STORE_NAME_ARTICLES_POPULAR, STORE_NAME_ARTICLES_RECOMMENDATION } from '@/store/store.list'

export default {
  namespaced: true,
  modules: {
    [STORE_NAME_ARTICLES_POPULAR]: articlesPopular,
    [STORE_NAME_ARTICLES_RECOMMENDATION]: articlesRecommendation
  }
}
