import {
  BACKGROUNDS_MAIN_GETTER_GET_MAIN_SLIDE_BG_LIST,
  BACKGROUNDS_MAIN_GETTER_GET_URL_LIST
} from '@/store/modules/backgrounds/main/backgroundsMain.constant'
export default {
  [BACKGROUNDS_MAIN_GETTER_GET_MAIN_SLIDE_BG_LIST]: (state) => {
    return state.mainSliderBackgrounds
  },
  [BACKGROUNDS_MAIN_GETTER_GET_URL_LIST]: (state) => {
    return state.mainSliderBackgrounds.map(item => item.url)
  }
}
