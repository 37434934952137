import getters from '@/store/modules/modals/getters'
import modalsInstitutions from '@/store/modules/modals/institution/modalsInstitutions'
import mutations from '@/store/modules/modals/mutations'
import { STORE_NAME_MODALS_INSTITUTION } from '@/store/store.list'

export default {
  namespaced: true,
  modules: {
    [STORE_NAME_MODALS_INSTITUTION]: modalsInstitutions
  },
  state: {
    isOpen: false,
    nested: 0
  },
  getters,
  mutations
}
