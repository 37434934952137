import { isProcessDevelop } from './process'

export const debugDelay = (data = {}, ms = 1000) => {
  if (isProcessDevelop) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (typeof data === 'function') {
          try {
            resolve(data())
          } catch (e) {
            reject(e)
          }
        } else {
          resolve(data)
        }
      }, ms)
    })
  }
  return data
}
// eslint-disable-next-line no-unexpected-multiline
export const debugLog = ((cl) => {
  console.log = function () {
    return isProcessDevelop ? cl(...arguments) : true
  }
})(console.log)
