export const STORE_NAME_EVENTS = 'events'
export const STORE_NAME_SYSTEM = 'system'
export const STORE_NAME_SYSTEM_BREADCRUMB = 'system-breadcrumb'
export const STORE_NAME_SYSTEM_LOADING = 'system-loading'
export const STORE_NAME_DOCUMENTS = 'documents'
export const STORE_NAME_INSTITUTIONS = 'institutions'
export const STORE_NAME_TEACHERS = 'teachers'
export const STORE_NAME_REVIEWS = 'reviews'
export const STORE_NAME_PROMO = 'promo'
export const STORE_NAME_GEO = 'geo'
export const STORE_NAME_GEO_COMPANIES = 'geoCompanies'
export const STORE_NAME_MODALS = 'modals'
export const STORE_NAME_MODALS_INSTITUTION = 'modal-institution'
export const STORE_NAME_BACKGROUNDS = 'backgrounds'
export const STORE_NAME_BACKGROUNDS_MAIN = 'backgrounds-main'
export const STORE_NAME_ARTICLE = 'article'
export const STORE_NAME_ARTICLES = 'articles'
export const STORE_NAME_ARTICLES_POPULAR = 'articles-popular'
export const STORE_NAME_ARTICLES_RECOMMENDATION = 'articles-recommendation'
export const STORE_NAME_FORMS = 'forms'
export const STORE_NAME_FORMS_ORDER = 'forms-order'
export const STORE_NAME_KINDEREDU = 'kinderedu'
export const STORE_NAME_KINDEREDU_POPULAR_INSTITUTIONS = 'kinderedu-popular-institutions'

export const STORE_PATH_KINDEREDU = STORE_NAME_KINDEREDU
export const STORE_PATH_KINDEREDU_POPULAR_INSTITUTIONS = `${STORE_PATH_KINDEREDU}/${STORE_NAME_KINDEREDU_POPULAR_INSTITUTIONS}`

export const STORE_PATH_ARTICLES = STORE_NAME_ARTICLES
export const STORE_PATH_ARTICLES_POPULAR = `${STORE_PATH_ARTICLES}/${STORE_NAME_ARTICLES_POPULAR}`
export const STORE_PATH_ARTICLES_RECOMMENDATION = `${STORE_PATH_ARTICLES}/${STORE_NAME_ARTICLES_RECOMMENDATION}`
export const STORE_PATH_EVENTS = STORE_NAME_EVENTS
export const STORE_PATH_SYSTEM = STORE_NAME_SYSTEM
export const STORE_PATH_SYSTEM_BREADCRUMB = `${STORE_PATH_SYSTEM}/${STORE_NAME_SYSTEM_BREADCRUMB}`
export const STORE_PATH_SYSTEM_LOADING = `${STORE_PATH_SYSTEM}/${STORE_NAME_SYSTEM_LOADING}`
export const STORE_PATH_DOCUMENTS = STORE_NAME_DOCUMENTS
export const STORE_PATH_INSTITUTIONS = STORE_NAME_INSTITUTIONS
export const STORE_PATH_TEACHERS = STORE_NAME_TEACHERS
export const STORE_PATH_REVIEWS = STORE_NAME_REVIEWS
export const STORE_PATH_PROMO = STORE_NAME_PROMO
export const STORE_PATH_GEO = STORE_NAME_GEO
export const STORE_PATH_GEO_COMPANIES = `${STORE_PATH_GEO}/${STORE_NAME_GEO_COMPANIES}`
export const STORE_PATH_MODALS = STORE_NAME_MODALS
export const STORE_PATH_MODALS_INSTITUTION = `${STORE_PATH_MODALS}/${STORE_NAME_MODALS_INSTITUTION}`
export const STORE_PATH_BACKGROUNDS = STORE_NAME_BACKGROUNDS
export const STORE_PATH_BACKGROUNDS_MAIN = `${STORE_PATH_BACKGROUNDS}/${STORE_NAME_BACKGROUNDS_MAIN}`
export const STORE_PATH_ARTICLE = STORE_NAME_ARTICLE
export const STORE_PATH_FORMS = STORE_NAME_FORMS
export const STORE_PATH_FORMS_ORDER = `${STORE_PATH_FORMS}/${STORE_NAME_FORMS_ORDER}`
