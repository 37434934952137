import actions from '@/store/modules/geo/actions'
import geoCompanies from '@/store/modules/geo/companies/geoCompanies'
import getters from '@/store/modules/geo/getters'
import mutations from '@/store/modules/geo/mutations'
import { STORE_NAME_GEO_COMPANIES } from '@/store/store.list'

export default {
  namespaced: true,
  modules: {
    [STORE_NAME_GEO_COMPANIES]: geoCompanies
  },
  state: {
    city: undefined,
    cityWithType: undefined,
    isoCode: undefined,
    lat: undefined,
    lon: undefined
  },
  actions,
  getters,
  mutations
}
