import getters from '@/store/modules/common/requested/getters'
import mutations from '@/store/modules/common/requested/mutations'

export default {
  state: {
    isRequested: false,
    isRequestComplete: false,
    isRequestError: false,
    errors: []
  },
  mutations,
  getters
}
