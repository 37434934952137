import actions from '@/store/modules/articles/recommendation/actions'
import getters from '@/store/modules/articles/recommendation/getters'
import mutations from '@/store/modules/articles/recommendation/mutations'
import commonLoader from '@/store/modules/common/loader/commonLoader?articles-reccomendation'
import commonRequested from '@/store/modules/common/requested/commonRequested?articles-reccomendation'

export default {
  namespaced: true,
  modules: {
    commonLoader,
    commonRequested
  },
  state: {
    item: {
      values: [],
      page: 0
    }
  },
  mutations,
  getters,
  actions
}
