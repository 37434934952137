<template>
    <div class="on-top-button" @click="handleClick">
      <base-icon icon="on-top-arrow"/>
    </div>
</template>

<script>
import BaseIcon from '@/components/base/icon/BaseIcon'
export default {
  name: 'BaseOnTopButton',
  components: { BaseIcon },
  methods: {
    handleClick () {
      window.scrollTo(0, 0)
    }
  }
}
</script>
