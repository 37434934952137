import actions from '@/store/modules/article/actions'
import getters from '@/store/modules/article/getters'
import mutations from '@/store/modules/article/mutations'

export default {
  namespaced: true,
  state: {
    type: undefined,
    value: undefined
  },
  mutations,
  getters,
  actions
}
