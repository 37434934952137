import commonLoader from '@/store/modules/common/loader/commonLoader?forms-form-order'
import commonRequested from '@/store/modules/common/requested/commonRequested?forms-form-order'
import actions from '@/store/modules/forms/order/actions'
import getters from '@/store/modules/forms/order/getters'
import mutations from '@/store/modules/forms/order/mutations'

export default {
  namespaced: true,
  modules: {
    commonLoader,
    commonRequested
  },
  state: {
    person: '',
    email: '',
    phone: '',
    agreement: false
  },
  actions,
  getters,
  mutations
}
