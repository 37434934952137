import commonLoader from '@/store/modules/common/loader/commonLoader?promotions-kinderedu-popular-institutions'
import commonRequested from '@/store/modules/common/requested/commonRequested?promotions-kindertedu-popular-institutions'
import actions from '@/store/modules/kinderedu/popular-institutions/actions'
import getters from '@/store/modules/kinderedu/popular-institutions/getters'
import mutations from '@/store/modules/kinderedu/popular-institutions/mutations'

export default {
  namespaced: true,
  modules: {
    commonLoader,
    commonRequested
  },
  state: {
    item: {
      values: [],
      page: 0
    }
  },
  getters,
  mutations,
  actions
}
