import { debugDelay } from '@/lib/utils/env/debug'
import {
  COMMON_LOADER_GETTER_IS_LOADING,
  COMMON_LOADER_MUTATION_TOGGLE_LOADING
} from '@/store/modules/common/loader/common.loader.constant'
import { TEACHERS_ACTION_FETCH, TEACHERS_MUTATIONS_ADD_ITEMS } from '@/store/modules/teachers/teachers.constant'

export default {
  async [TEACHERS_ACTION_FETCH] ({ commit, getters }, payload) {
    if (getters[COMMON_LOADER_GETTER_IS_LOADING]) {
      return false
    }
    const mock = require('@/mock/SectionTeachers.json')
    commit(COMMON_LOADER_MUTATION_TOGGLE_LOADING, true)
    const item = await debugDelay(mock)
    commit(COMMON_LOADER_MUTATION_TOGGLE_LOADING, false)
    commit(TEACHERS_MUTATIONS_ADD_ITEMS, item)
  }
}
