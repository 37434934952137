import commonLoader from '@/store/modules/common/loader/commonLoader?store-institutions'
import mutations from '@/store/modules/institutions/mutations'
import getters from '@/store/modules/institutions/getters'
import actions from '@/store/modules/institutions/actions'

export default {
  namespaced: true,
  modules: {
    commonLoader
  },
  state: {
    item: {
      values: [],
      page: 0
    }
  },
  mutations,
  getters,
  actions
}
