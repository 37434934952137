import { isProcessDevelop } from '~/lib/utils/env/process'

export const parseResponseSuccess = response => {
  const data = response?.response?.body || response?.body
  if (typeof data === 'undefined') {
    throw new Error('Response not consist valid form value')
  }

  const { msgCode, msgText, traceId, ...result } = data

  const code = parseInt(msgCode)
  if (!code || (code >= 200 && code < 300)) {
    return msgText ? { msgText, ...result } : result
  } else if (code >= 400 && code < 500) {
    throw new Error(JSON.stringify({ msgText, traceId }))
  } else {
    throw new Error(`Внутренняя ошибка приложения. Выполните запрос позднее. Идентификатор ошибки ${traceId}`)
  }
}

export const parseResponseError = error => {
  if (isProcessDevelop) {
    console.error('DEVELOPMENT_MESSAGE: ', error, '\n API_URL: ', error.url)
  }
  return {
    msgCode: error?.status || 503,
    msgText: error
  }
}
